import React, { useEffect, useState, useContext } from 'react';
import Swal from 'sweetalert2';
// import './FileUpload.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { CommonContext } from './CommonContext';
import Modal from 'react-modal';
import InsideHeader from './InsideHeader';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader'
let { appname, lambda } = window.app;

const FileUpload = () => {
    const [files, setFiles] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadFlag, setuploadFlag] = useState(false);
    const [limitExceed, setLimitExceed] = useState(false);
    const { userData, setUserData } = useContext(CommonContext);
    const [isOpen, setIsOpen] = useState(false);
    const [tags, setTags] = useState('');
    const [tagerror, setTagError] = useState(false);
    // const [onRequestClose, setOnRequestClose] = useState(false);
    const navigate = useNavigate();
    const [image, setImg] = useState('');
    const [activeLoad, setActiveLoad] = useState(false);
    const [showDocAlert, setShowDocAlert] = useState(false);
    const location = useLocation();
    const [singleDeleteIndex, setSingleDeleteIndex] = useState(false);

    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);


            } else {
                setTimeout(checkSiteObject, 50); // Check again after a short delay
            }
        };

        checkSiteObject();
        if(userData == null){
            fetchUserData()
        }
        
    }, []);

    const handleDeletePopup = (e) => {
        setShowDocAlert(true)
    }

    const fetchUserData = async () => {
        try {
          const clientid = localStorage.getItem('clientid');
const token = localStorage.getItem('token');
          const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);
    
          if (response.status === 200 && response.data?.result?.[0]) {
            setUserData(response.data.result[0]);
          } else {
            throw new Error('Unexpected response format');
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
        }
      };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        // const selectedFilesSize = selectedFile.size; //for single file
        const selectedFiles = Array.from(event.target.files);  //for multiple files
        const selectedFilesSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);  //for multiple files
        const newTotalSize = totalSize + selectedFilesSize;

        if (selectedFiles.some(file => file.size > userData?.totalStorage ) || newTotalSize > userData?.totalStorage) {
            setLimitExceed(true)

        } else {
            setLimitExceed(false)
        }

        setFiles([...files, ...selectedFiles]); //for multiple files
        // setFiles([selectedFile]); //for single file

        setTotalSize(newTotalSize);
        setuploadFlag(true)
    };

    // Helper function to get video duration
    const getVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                resolve(video.duration);
            };

            video.onerror = () => {
                reject(new Error('Error loading video metadata'));
            };

            video.src = URL.createObjectURL(file);
        });
    };
    const formatDuration = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };



    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };
    const handleSubmit = (event) => {
        event.preventDefault();

			const totalUserSize = totalSize + Array.from(files).reduce((acc, file) => acc + file.size, 0);
            // userData?.totalStorage
            if (totalUserSize > userData?.totalStorage ) { // 1 GB limit
                Swal.fire({
                    title: formatBytes(userData?.totalStorage ?? 0) + ' Limit Exceeded',
                    text: 'You need to upgrade to upload files larger than 1GB. Do you want to upgrade?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Upgrade',
                    cancelButtonText: 'No, Cancel'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/subscription');  // Redirect to payment page
                    }
                });
                return;
            }
            
        const token = localStorage.getItem('token');
        if (!token) {
            // Swal.fire({
            //     title: 'Sign In Required',
            //     text: 'Please sign in to upload files.',
            //     icon: 'info',
            //     confirmButtonText: 'OK'
            // }).then(() => {
            navigate('/signin', { state: { files, totalSize, tags } });
            // });
        } else if (location?.state?.returnRoute === "event") {
            navigate('/event', { state: { files, totalSize, tags, albumItem: location?.state?.albumItem, selectedImages: location?.state?.selectedImages } });
        } else
        // if (tags) 
        {
            // handleUpload();
            // navigate('/mycontent', { state: { files, totalSize } });
            navigate('/all-files', { state: { files, totalSize, tags } });

        }
        // else {
        //     setTagError("Please enter tags")
        // }
    };





    useEffect(() => {
        if (files.length <= 0) {
            setuploadFlag(false)
        }


    }, [files])

    const handleSelectFile = (index) => {
        setSelectedFiles(prev => {
            if (prev.includes(index)) {
                return prev.filter(i => i !== index);
            }
            return [...prev, index];
        });
    };

    const termsClick = (e, page) => {
        navigate('/' + page);
    }
    const handleDeleteSelected = (e) => {
        console.log("singleDeleteIndex ", singleDeleteIndex)
        if (singleDeleteIndex) {
            console.log("singleDeleteIndex if ", singleDeleteIndex)
            //  handleDelete(singleDeleteIndex)
            //     // setSingleDeleteIndex(false)
        } else {
            console.log("singleDeleteIndex else ", singleDeleteIndex)
            const newFiles = files.filter((_, i) => !selectedFiles.includes(i));
            const newTotalSize = newFiles.reduce((acc, file) => acc + file.size, 0);
            if (newFiles.some(file => file.size > userData?.totalStorage) || newTotalSize > userData?.totalStorage) {
                setLimitExceed(true)

            } else {
                setLimitExceed(false)
            }
            setFiles(newFiles);
            setTotalSize(newTotalSize);
            setSelectedFiles([]);
            setShowDocAlert(false)
        }


    };
    const handleDelete = (index) => {
        console.log(index, "indexxx")
        const newFiles = files.filter((_, i) => i !== index);
        const newTotalSize = newFiles.reduce((acc, file) => acc + file.size, 0);
        setFiles(newFiles);
        if (newFiles.some(file => file.size > userData?.totalStorage) || newTotalSize > userData?.totalStorage) {
            setLimitExceed(true)

        } else {
            setLimitExceed(false)
        }
        setTotalSize(newTotalSize);
        setSelectedFiles([]);
        setShowDocAlert(false)
        setSingleDeleteIndex(false)
    };

    const openModal = () => {
        setuploadFlag(false)
        console.log('openModal')
        setIsOpen(true);
    };


    const closeModal = () => {
        setIsOpen(false);
    };

    const selectedFileRemoved = (e) => {
        e.preventDefault()
        console.log("selectedFileRemoved ", singleDeleteIndex)
        if (singleDeleteIndex) {

            setSingleDeleteIndex(false)
        }
    }
    console.log("returrrrn ", singleDeleteIndex)
    return (
        <div id="page-top">
    {/* <Navbar /> */}
    <InsideHeader flag={true} />
    {activeLoad && <Loader />}

    <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Upload Videos"
        className="modal"
        overlayClassName="overlay"
    >
        <div className="step-header text-center" id="modal-header">
            <h1 className="wizard-heading mb-4">All Set! Upload Images here?
                <div className="help-block" id="help-block">
                    <span className="help">?</span>
                </div>
            </h1>
        </div>

        <div className="upload-zone" id="upload-zone">
            <div className="upload-icon" id="upload-icon">
                <span className="material-icons">file_upload</span>
            </div>
            <h6 className="mt-3 mb-3" id="drag-drop-text">Drag and Drop files here</h6>

            <input
                type="file"
                id="file-upload"
                accept="video/mp4, image/jpeg, image/png"
                className="file-input"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />

            <label
                htmlFor="file-upload"
                className="btn btn-primary rounded-pill px-3 py-2 mb-2 mb-lg-0 mt-3"
                id="choose-files-btn"
            >
                Choose files here
            </label>
            <p className="upload-zone-text mt-5" id="terms-text">
                By submitting your images to Fusion Pics, you acknowledge that you agree
                to Fusion Pics <a onClick={(e) => termsClick(e, 'terms')}>Terms of Service</a> &amp; <a onClick={(e) => termsClick(e, 'privacy')}>Privacy Policy</a>
            </p>
        </div>

    </Modal>

    <div className="wizard-body upload_body upload_image" id="wizard-body">
        <div className="steplist-wrapper" id="steplist-wrapper">
        </div>
        <div className="step-body" id="step-body">
            {uploadFlag && <div className="step-header flex-spread" id="step-header">
                <h1 className="wizard-heading" id="selected-items-count">{files.length} Items selected</h1>
                <div className="header-actions d-flex" id="header-actions">
                    {selectedFiles.length > 0 && (
                        <button className="btn btn-outline me-2" onClick={(e) => handleDeletePopup(e)} id="delete-btn">Delete</button>
                    )}
                    <button onClick={openModal} className="btn btn-fill" id="add-files-btn">
                        <span className="material-symbols-outlined me-1">upload</span>Add Files
                    </button>
                </div>
            </div>}

            {!uploadFlag && <>
                <div className="step-header text-center" id="step-header-text">
                    <h1 className="wizard-heading mb-4" id="all-set-heading">All Set! Upload Images here?
                        <div className="help-block" id="help-block">
                            <span className="help">?</span>
                        </div>
                    </h1>
                    {isOpen && 
                        <div className="action-div" id="action-div">
                            <button className="icon-button" onClick={() => { setuploadFlag(true); setIsOpen(false) }} id="close-modal-btn">
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                    }
                </div>

                <div className="upload-zone" id="upload-zone">
                    <div className="upload-icon" id="upload-icon">
                        <span className="material-icons">file_upload</span>
                    </div>
                    <h6 className="mt-3 mb-3" id="drag-drop-text">Drag and Drop files here</h6>

                    <input
                        type="file"
                        id="file-upload"
                        accept="video/mp4, image/jpeg, image/png"
                        className="file-input"
                        multiple
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />

                    <label
                        htmlFor="file-upload"
                        className="btn btn-primary rounded-pill px-3 py-2 mb-2 mb-lg-0 mt-3"
                        id="choose-files-btn"
                    >
                        Choose files here
                    </label>
                    <p className="upload-zone-text mt-5" id="terms-text">
                        By submitting your images to Fusion Pics, you acknowledge that you agree
                        to Fusion Pics <a onClick={(e) => termsClick(e, 'terms')}>Terms of Service</a> &amp; <a onClick={(e) => termsClick(e, 'privacy')}>Privacy Policy</a>
                    </p>
                </div>
            </>}
            
            {uploadFlag && <>
                <div className="loading-files loading_listfiles" id="loading-files">
                    {files.length > 0 && (
                        <div className='loading-files-scroll' id="loading-files-scroll">
                            {files.map((file, index) => (
                                <div className="loading-files-block" key={index} id={`file-${index}`}>
                                    <div className="loading-title-section" id="loading-title-section">
                                        <input
                                            type="checkbox"
                                            onChange={() => handleSelectFile(index)}
                                            checked={selectedFiles.includes(index)}
                                            id={`file-checkbox-${index}`}
                                        />
                                        {file.type?.startsWith('video/') ? 
                                            <span className="material-symbols-outlined movie-icon ms-2 me-1">movie</span> :
                                            <span className="material-symbols-outlined movie-icon ms-2 me-1">image</span>
                                        }
                                        <p className="ms-2" id={`file-name-${index}`}>{file.name}</p>
                                    </div>
                                    <div className="d-flex align-items-center size" id="file-size">
                                        <div className="size-div" id="size-div">
                                            <span id={`file-size-${index}`}>{formatBytes(file.size)}</span>
                                        </div>
                                        <div className="action-div" id="action-div">
                                            <button className="icon-button" onClick={(e) => { setSingleDeleteIndex(index); handleDeletePopup(e) }} id={`delete-btn-${index}`}>
                                                <span className="material-symbols-outlined">close</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="loading-files-block storage-warning-block">
                                    {limitExceed &&
                                        <div className="loading-title-section">
                                            <span className="material-symbols-outlined movie-icon">report</span>
                                            <p className="ms-2">Total File size exceeded free limit of 1GB. Please upgrade the storage or remove files</p>
                                        </div>}
                                    <div className="d-flex align-items-center total-div">
                                        <p>Total</p>
                                        <div className="size-div">
                                            <span>{formatBytes(totalSize)}</span>
                                        </div>
                                    </div>
                                </div>
                <div className="tags">
                                    <h1 className="wizard-heading">Tag Files</h1>
                                    <p>Tagging enables to search for your content that you've added. </p>
                                    <div className="form-group">
                                        <label>Enter Tags</label>
                                        <input type="text" id="enter_tages" onChange={(e) => {
                                            setTags(e.target.value);
                                            // setTagError(false) 
                                        }} className="form-control" placeholder="Eg:Sunday Picnic, Engagement Pics" />
                                    </div>

                                </div>
                                {tagerror && <p className="text-danger">{tagerror}</p>}
                                <button
                                    className="btn btn-primary rounded-pill px-3 py-2 mb-2 mb-lg-0 mt-3"
                                    onClick={handleSubmit}
                                    id="submit"
                                >
                                    Submit
                                </button>
            </>}

        </div>
        {showDocAlert &&
                    <>
                        <div className="fade modal-backdrop show"></div>
                        <div className="large_popup delete_popup" style={{ "position": "fixed", "left": "50%", top: "40%", zIndex: 9999 }} >
                            <div className="modal-dialog"><div className="modal-content">
                                <div className="modal-body">
                                    <button className="close-btn"><span className="material-icons" onClick={e => { setShowDocAlert(false); selectedFileRemoved(e) }}>close</span></button>
                                    <span className="material-icons delete-icon">delete_outline</span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone.</p>
                                    <p>Are you sure you want to delete {selectedFiles?.length > 1 ? "Files" : "File"}?</p>
                                    <div className="popup-footer">
                                        {singleDeleteIndex ?
                                            <button className="fill_btn" onClick={(e) => handleDelete(singleDeleteIndex)}> Yes, Delete It</button>
                                            : singleDeleteIndex === 0 ?
                                                <button className="fill_btn" onClick={(e) => handleDelete(singleDeleteIndex)}> Yes, Delete It</button> :
                                                <button className="fill_btn" onClick={(e) => handleDeleteSelected(e)}> Yes, Delete {selectedFiles?.length > 1 ? "Them" : "It"}</button>}

                                    </div>
                                </div></div></div></div></>
                }

    </div>

    <Footer />
</div>

    );
};

export default FileUpload;
