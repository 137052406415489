import React, { useState } from 'react'; // Remove lazy loading temporarily
import FilerobotImageEditor from 'react-filerobot-image-editor'; // Directly import without lazy
import InsideHeader from './InsideHeader';

function ImageEditorPage() {
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);

    const openImgEditor = () => {
        setIsImgEditorShown(true);
    };

    const closeImgEditor = () => {
        setIsImgEditorShown(false);
    };

    const handleImageSave = (editedImageObject) => {
        console.log('Saved image:', editedImageObject);
    };

    return (
        <div id="page-top">
    <InsideHeader flag={true} id="inside-header-unique-id" />
    <div className='right-section' id="right-section-unique-id">
        <div className="inner-body blankpage" id="inner-body-unique-id">
            <div className="text-center transform-center" id="transform-center-unique-id">
                <button id="open-img-editor-button" onClick={openImgEditor}>Open Filerobot Image Editor</button>
                {isImgEditorShown && (
                    <FilerobotImageEditor
                        id="filerobot-image-editor-unique-id"
                        source="https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
                        onSave={handleImageSave}
                        onClose={closeImgEditor}
                    />
                )}
            </div>
        </div>
    </div>
</div>

    );
}

export default ImageEditorPage;
