import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ReactPlayer from 'react-player'
import Modal from 'react-bootstrap/Modal';
import Loader from './Loader'
let { appname, lambda } = window.app;

const ViewAlbum = () => {
  const [Images, setImages] = useState();
  const [selectedImages, setSelectedImages] = useState([]); // Store selected images

  const [albumItem, setAlbumItem] = useState([]);
  const [image, setImg] = useState('');
  const [videoSource, setVideoSource] = useState('');
  const [geolocation, setGeoLocation] = useState('');
  const { id, clientId, typeId } = useParams();  // Extracting the id from the URL

  const [privateData, setPrivate] = useState(false);
  const [privateStatus, setPrivateStatus] = useState(false);

  const [albumProcess, setAlbumStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAutoSliding, setIsAutoSliding] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [videoGallery, setVideoGallery] = useState([]);
  const [index, setIndex] = useState(-1);

  const [currentImage, setcurrentImage] = useState(null);
  const [nextIndex, setnextIndex] = useState(null);
  const [nextImage, setnextImage] = useState(null);
  const [prevIndex, setprevIndex] = useState(null);
  const [prevImage, setprevImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(false);
  const [playerPath, setplayerPath] = useState(null);
  const [tabView, setTabView] = useState(true);
  const [albumImage, setAlbumImage] = useState('images/app/images/alumbsDefault.jpg');
  const [activeLoad, setActiveLoad] = useState(false);
  const [configLoaded, setconfigLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);


  let albumStatus = true
  let configInterval;
  useEffect(() => {
    let slideInterval;
    if (isAutoSliding) {
      slideInterval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % gallery.length);
      }, 5000); // Change image every 5 seconds
    }

    return () => clearInterval(slideInterval); // Cleanup interval on unmount
  }, [isAutoSliding, gallery.length]);

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        //    clearInterval(configInterval)
        setImg(window.site.common.imageCloudfront);
        setVideoSource(window.site.common.sourceCloudFront)
        setGeoLocation(window.site.common.geoLocatoinCloudfrontUrl)
        setconfigLoaded(true)
      } else {
        setTimeout(checkSiteObject, 1000); // Check again after a short delay
      }
    };

    // configInterval = setInterval(() => {
    checkSiteObject();
    //   }, 5000);

  }, []);

  useEffect(() => {
    const currentImage = gallery[index];
    setcurrentImage(currentImage)
    const nextIndex = (index + 1) % gallery.length;
    setnextIndex(nextIndex)
    const nextImage = gallery[nextIndex] || currentImage;
    setnextImage(nextImage)
    const prevIndex = (index + gallery.length - 1) % gallery.length;
    setprevIndex(prevIndex)
    const prevImage = gallery[prevIndex] || currentImage;
    setprevImage(prevImage)



  }, [index]);


  useEffect(() => {
    if (currentImage) {
      const img = new Image();
      img.src = currentImage?.original;
      img.onload = () => setIsLoading(false);
    }

  }, [currentImage]);

  useEffect(() => {
    // console.log('i fire once');
    if (albumStatus) {
      // console.log("albumStatus ", albumStatus)
      // setAlbumStatus(false)
      albumStatus = false
      fetchAlbumInfo();
    }
  }, [configLoaded]);


  useEffect(() => {
    // console.log("privateStatus ", privateStatus && privateStatus?.[0] && privateStatus?.[0].status)
    if (privateStatus && privateStatus?.[0] && privateStatus?.[0].status == "Pending") {
      shareData(privateStatus?.[0].code)
    } else if (privateStatus && privateStatus?.[0] && privateStatus?.[0].status == "Approved") {

      albumData(albumItem)
    } else if (privateStatus && privateStatus?.[0] && privateStatus?.[0].status == "Reject") {
      setAlbumStatus("Album Rejected");
      // localStorage.removeItem("c")
    }
  }, [privateStatus]);

  useEffect(() => {
debugger
    //  console.log("albunItem", albumItem, albumStatus)
    if (typeId == albumItem?.private) {
      let codeValue = localStorage.getItem("c") || privateData;
      let foundImages = []
      if (albumItem?.contentids.length > 0) {
        foundImages = albumItem?.contentids.filter(v => v.filetype.startsWith('image/'));
      }
      if (foundImages && foundImages.length > 0) {
        setAlbumImage(foundImages[0]?.filepath)
      }

      if (codeValue) {
        let typeCode = JSON.parse(codeValue)
        // console.log("typeCode", typeCode)
        let texists = []
        if (typeCode && typeCode.length > 0) {
          texists = typeCode?.filter((item) => {
            return item.t == typeId
          });
        }


        // console.log("texists", texists)
        if (texists && texists?.length > 0 && texists?.[0].t == typeId) {
          setPrivate(texists?.[0].c);
          shareData(texists?.[0].c)
        } else {
          shareLink(albumItem?.albumName, typeId)
        }

      } else {
        shareLink(albumItem?.albumName, typeId)
      }
    } else if (typeId == albumItem.public) {
      setActiveLoad(true)
      albumData(albumItem)
    }
  }, [albumItem]);



  const handleClick = async (index, item) => {

    setIndex(index)
    setIsOpen(true);
    setIsAutoSliding(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsAutoSliding(false); // Stop auto-sliding when lightbox closes
    setIndex(-1)
  };

  const handleMovePrev = (e) => setIndex(prevIndex);
  const handleMoveNext = (e) => setIndex(nextIndex);

  const handleImageLoadError = (e) => {
    // console.log('ieee ', e)
  }

  const handlePlayVideo = (item) => {

    setVideoPlayer(true)
    let filepath = videoSource + "/" + item.filepath + item.filename

    if (item?.video?.m3u8?.proxyUrl) {
      filepath = videoSource + item?.video?.m3u8?.proxyUrl
    }
    setplayerPath(filepath)
  };


  const fetchAlbumInfo = async () => {
    // console.log("albuminfo")
    try {

      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientId}&albumid=${id}`);
      setAlbumItem(response.data.result?.[0]);

    } catch (error) {
      console.error('Error fetching album info:', error);
    }
  };

  const shareLink = async (title, typeId) => {
    
    if(!privateData){
    try {
      let nameValue = new URLSearchParams(window.location.search).get("name")
      const geoResponse = await axios.get(geolocation);
      console.log('geoResponse ', geoResponse)

      let country = geoResponse?.data?.headers?.["cloudfront-viewer-country-name"][0].value
      let state = geoResponse?.data?.headers?.["cloudfront-viewer-country-region-name"][0].value
      let city = geoResponse?.data?.headers?.["cloudfront-viewer-city"][0].value

      let object = {
        clientid: clientId,
        albumid: id,
        albumName: title,
        name: nameValue,
        location: city + ", " + state + ", " + country
      }

      console.log('object ', object)
      const response = await axios.post(`${lambda}/shareLink?appname=${appname}&clientid=${clientId}&albumid=${id}`, object);
      // console.log("rrr ", response)
      setPrivate(response.data.result);
      shareData(response.data.result)
      setApiLoaded(true)
      let storage = localStorage.getItem("c")
      let arrayStorage = []
      if (storage) {
        storage = JSON.parse(storage)
        // console.log(storage, "storage")
        arrayStorage = storage
      }
      let obj = {
        t: typeId,
        c: response.data.result
      }
      arrayStorage.push(obj)
      // console.log(arrayStorage, "arrayStorage")
      localStorage.setItem("c", JSON.stringify(arrayStorage))
    } catch (error) {
      console.error('Error fetching album info:', error);
    }
  }
  };

  const shareData = async (codeValue) => {
    
    try {
      const response = await axios.get(`${lambda}/sharesList?appname=${appname}&clientid=${clientId}&code=${codeValue}`);
      // console.log("setPrivateStatus result ", response)
      setPrivateStatus(response.data.result);
      setActiveLoad(true)

    } catch (error) {
      console.error('Error fetching album info:', error);
    }
  };




  const albumData = async (albumItem) => {
    let imagesArray = []
    let videoArray = []

    if (albumItem?.contentids && albumItem?.contentids?.length > 0) {


      let elements = document.querySelectorAll('[data-testid=grid-gallery-item]');
      let elementDivWidth = elements?.[0]?.clientWidth || 145
      albumItem?.contentids?.map((item) => {
        let width = '?width=' + elementDivWidth
        if (item?.filetype?.startsWith('image/')) {
          let obj = {
            src: `${image}${item.filepath}${width}`,
            original: `${image}${item.filepath}`,
          }
          imagesArray.push(obj)
        } else {
          videoArray.push(item)
        }

      });
      setGallery(imagesArray)
      setVideoGallery(videoArray)

      setTimeout(() => {
        setImagesLoaded(true)
      }, 5000)

    }
  };
  // console.log("setVideoGallery ", videoGallery)
  // console.log("privateStatus ", privateStatus)
  return (
    // <div id="page-top">
    <div className="share_page" id="sharePage">
    {activeLoad && privateStatus && privateStatus?.[0] && privateStatus?.[0].status == "Pending" && configLoaded ? 
      <div className="otp-share" id="otpShare">
        <div className="img-graphics" id="imgGraphics">
          <img src={`${image}images/app/images/top.png`} className="top-graphic" id="topGraphic" />
          <img src={`${image}images/app/images/center.png`} className="center-graphic" id="centerGraphic" />
          <img src={`${image}images/app/images/bottom-2.png`} className="bottom-2-graphic" id="bottom2Graphic" />
          <img src={`${image}images/app/images/bottom-1.png`} className="bottom-1-graphic" id="bottom1Graphic" />
        </div>
        <div className="container" id="otpContainer">
          <div className="justify-content-center row" id="otpRow">
            <div className="col-md-8 col-lg-6 col-xl-5" id="otpColumn">
              <img src={`${image}images/app/images/logo-dark1.png`} id="otpLogo" />
              <p className="album-name mt-4 mb-3 text-center" id="otpAlbumName">View Album</p>
              <img src={`${image}${albumImage}`} className="album-img" id="otpAlbumImg" />
              <p className="album-name text-center" id="albumNameText">{albumItem?.albumName}</p>
              <p className="mt-4 text-center" id="otpInstruction">To get access, share the below <br />One Time Password with the owner of this album</p>
              <h6 className="text-center" id="otpPrivateData">{privateData}</h6>
            </div>
          </div>
        </div>
      </div>
    : activeLoad && configLoaded ? 
      <>
        <div className="main_bg" id="mainBg">
          <img src={`${image}images/app/images/pics-view-bg.png`} alt="Bg" id="backgroundImage" />
        </div>
        <div className="header" id="headerSection">
          <div className="logo" id="logoSection">
            <img src={`${image}images/app/images/logo-white1.png`} alt="Logo" id="headerLogo" />
          </div>
          <h1 className="title" id="albumTitle">{albumItem?.albumName}</h1>
        </div>
  
        {videoGallery && videoGallery.length > 0 && gallery && gallery.length > 0 ? 
          <div className='menu' id="menuSection">
            <ul id="menuList">
              <li className={`${tabView ? 'active' : ""}`} onClick={() => setTabView(true)} id="photosTab">
                <a><img src={`${image}images/app/images/photo_library.svg`} id="photoIcon" /><p>Photos</p></a>
              </li>
              <li className={`${!tabView ? 'active' : ""}`} onClick={() => setTabView(false)} id="videosTab">
                <a><img src={`${image}images/app/images/video-tab.svg`} id="videoIcon" /><p>Videos</p></a>
              </li>
            </ul>
          </div>
        : ""}
  
        {isOpen && <span title={isAutoSliding ? "Autoslide Pause" : "Autoslide Play"} style={{ "position": "absolute", "cursor": "pointer", "zIndex": 9999, "right": "11vw", top: "5px", "color": "#ccc" }} onClick={() => setIsAutoSliding(!isAutoSliding)} id="autoslideControl">
          <span className="material-symbols-outlined icon" id="autoslideIcon">{isAutoSliding ? "pause_circle" : "play_circle"}</span>
        </span>}
  
        <div className="clips-container" id="clipsContainer">
          {albumProcess ? 
            <div className="alubum-empty-wrapper" id="albumEmptyWrapper">
              <div className="blankpage" id="blankPage">
                <div className="text-center transform-center" id="emptyMessage">
                  <img src={`${image}images/app/images/default-img.png`} alt="notavailable" id="emptyImage" />
                  <h3 id="emptyMessageText">{albumProcess}</h3>
                  <p className="txt-white" style={{ "color": "#fff" }} id="emptyMessageInstruction">Please contact the owner of this Album.</p>
                </div>
              </div>
            </div>
          : videoGallery && videoGallery.length == 0 && gallery && gallery.length == 0 ?
            <div className="alubum-empty-wrapper" id="albumEmptyWrapperNoMedia">
              <div className="blankpage" id="blankPageNoMedia">
                <div className="text-center transform-center" id="noMediaMessage">
                  <img src={`${image}images/app/images/default-img.png`} alt="notavailable" id="noMediaImage" />
                  <h3 id="noMediaMessageText">Photos not available!</h3>
                  <p className="txt-white" style={{ "color": "#fff" }} id="noMediaInstruction">Please contact the owner of this Album.</p>
                </div>
              </div>
            </div> 
          : ""}
  
          {tabView && gallery && gallery.length > 0 ? 
            !imagesLoaded ? 
              <Gallery 
                images={gallery.map((imageItem) => ({
                  ...imageItem,
                  src: `${image}images/app/images/thumbnail.jpg` // Load a smaller thumbnail initially
                }))} id="galleryThumbnail" />
            :
              <>
                <Gallery 
                  images={gallery} 
                  onClick={handleClick} 
                  enableImageSelection={false} 
                  id="galleryFullSize" />
                {isOpen && currentImage?.original && <Lightbox
                  mainSrc={currentImage?.original}
                  imageTitle={currentImage?.caption}
                  mainSrcThumbnail={currentImage?.src}
                  nextSrc={nextImage?.original}
                  nextSrcThumbnail={nextImage?.src}
                  prevSrc={prevImage?.original}
                  prevSrcThumbnail={prevImage?.src}
                  onCloseRequest={(e) => handleClose(e)}
                  onMovePrevRequest={(e) => handleMovePrev(e)}
                  onMoveNextRequest={(e) => handleMoveNext(e)}
                  onAfterOpen={(e) => handleImageLoadError(e)}
                  id="lightboxComponent"
                />}
              </>
          : 
            <div className="fusion-row" id="videoGallery">
              {videoGallery?.map((item) => (
                <div className="column10 g-3" key={item.contentid} id={`videoItem-${item.contentid}`}>
                  <div className="thumb-block" id={`thumbBlock-${item.contentid}`}>
                    <div className="dummy-thumb" onClick={() => handlePlayVideo(item)} id={`dummyThumb-${item.contentid}`}></div>
                    <img src={`${image}images/app/images/play-default.jpg`} className="thumb-img" alt="thumbnail" data-id={item.contentid} id={`thumbImg-${item.contentid}`} />
                  </div>
                </div>
              ))}
            </div>
          }
  
          <Modal className="modal fade video-popup" show={videoPlayer} id="videoModal">
            <div className="modal-body" id="modalBody">
              <button className="close-btn" id="closeBtn"><span className="material-icons" onClick={e => setVideoPlayer(false)} id="closeModalBtn">close</span></button>
              <div className='player-wrapper' id="videoPlayerWrapper"> <ReactPlayer url={playerPath} playing controls id="reactPlayer" />
              </div> 
            </div>
          </Modal>
        </div>
      </>
    : <Loader id="loader" />}
  </div>
  
    // {/* </div> */}


  );
};

export default ViewAlbum;
