import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InsideHeader from './InsideHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
let { appname, lambda } = window.app;

const MyAccount = (props) => {
    const [user, setUser] = useState({ name: '', personalemail: '', personalphone: '' });
    const [errors, setErrors] = useState({});
    const [passwordInput, setPasswordInput] = useState({
        password: '',
        confirmPassword: '',
        oldPassword: ''
    });
    const [image, setImg] = useState('');
    const [passwordError, setPasswordErr] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordShown, setPasswordShown] = useState(true);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [number, setNumber] = useState(false);
    const [upper, setUpper] = useState(false);
    const [limit, setLimit] = useState(false);
    const [lower, setLower] = useState(false);
    const [special, setSpecial] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [activeLoad, setActiveLoad] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        } else {
            fetchUserData();
        }
    }, [navigate]);

    useEffect(() => {
        const hasChanges =
          user.name !== name ||
          user.emailid !== email ||
          user.contactno !== phone;
        setIsDisabled(!hasChanges); // Disable button if no changes
      }, [user]);
    useEffect(() => {
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);
            } else {
                setTimeout(checkSiteObject, 50);
            }
        };
        checkSiteObject();
    }, []);
    const fetchUserData = async () => {
        try {
            const clientid = localStorage.getItem('clientid');
            const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);
            if (response.data?.result === "Client not found") {
                localStorage.removeItem("token");
                localStorage.removeItem("currentSessionClientTime");
                localStorage.removeItem("clientid");
                navigate('/signin');
            } else if (response.status === 200 && response.data?.result?.[0]) {
                if (response.data.result[0]?.name) {
                    setName(response.data.result[0]?.name)
                }
                if (response.data.result[0]?.emailid) {
                    setEmail(response.data.result[0]?.emailid)
                }
                if (response.data.result[0]?.personalphone) {
                    setPhone(response.data.result[0]?.personalphone)
                }
                setUser(response.data.result[0]);
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (err) {
            console.error('Error fetching user data:', err);
            setError(err.message);
        }
    };
    const validate = () => {
        let validationErrors = {};
        if (!user.name) {
            validationErrors.name = 'Name is required';
        } else if (user.name.length < 2) {
            validationErrors.name = 'Name must be at least 2 characters';
        }
        if (user.personalphone && !/^\d{10}$/.test(user.personalphone)) {
            validationErrors.personalphone = 'Phone number is invalid';
        }
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            try {
                setActiveLoad(true)
                const clientid = localStorage.getItem('clientid');
                const token = localStorage.getItem('token');
                const payload = {
                    name: user?.name,
                    personalphone: user?.personalphone,
                };
                await axios.post(`${lambda}/client?appname=${appname}&clientid=${clientid}`, payload); // Replace with your updateUser API endpoint
                setActiveLoad(false)
                toast.success("Profile updated successfully.", {
                    position: "bottom-center",
                    title: 'Success!'
                });
                if (user?.name) {
                    setName(user?.name)
                }
                if (user?.emailid) {
                    setEmail(user?.emailid)
                }
                if (user?.personalphone) {
                    setPhone(user?.personalphone)
                }

            } catch (error) {
                setActiveLoad(false)
                toast.error("Client not found. Please sign up.", {
                    position: "bottom-center"
                });
            }
        } else {
            setActiveLoad(false)
            setErrors(validationErrors);
        }
    };
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordInput(prevState => ({ ...prevState, [name]: value }));
    };
    const handleValidation = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        //for password 
        if (passwordInputFieldName === 'password' || passwordInputFieldName === 'oldPassword') {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            const passwordLength = passwordInputValue.length;
            const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
            const digitsPassword = digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword = minLengthRegExp.test(passwordInputValue);
            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
                setTimeout(() => setPasswordErr(""), 4000);
            }
            if (uppercasePassword) {
                var element = document.getElementById("err1");
                element.classList.add("vaild");
                setUpper(true);
            } else {
                var element = document.getElementById("err1");
                element.classList.remove("vaild");
                setUpper(false);
            }
            if (lowercasePassword) {
                var element = document.getElementById("err");
                element.classList.add("vaild");
                setLower(true);
            } else {
                var element = document.getElementById("err");
                element.classList.remove("vaild");
                setLower(false);
            }
            if (digitsPassword) {
                var element = document.getElementById("err2");
                element.classList.add("vaild");
                setNumber(true);
            } else {
                var element = document.getElementById("err2");
                element.classList.remove("vaild");
                setNumber(false);
            }
            if (specialCharPassword) {
                var element = document.getElementById("err3");
                element.classList.add("vaild");
                setSpecial(true)
            } else {
                var element = document.getElementById("err3");
                element.classList.remove("vaild");
                setSpecial(false)
            }
            if (minLengthPassword) {
                var element = document.getElementById("err4");
                element.classList.add("vaild");
                setLimit(true)
            } else {
                var element = document.getElementById("err4");
                element.classList.remove("vaild");
                setLimit(false)
            }
            if (limit && upper && special && lower && number && confirmPasswordError === "") {
                setError("")
            }
            setPasswordErr(errMsg);
        }
    }
    const handleRemoveclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.remove("ins-dsp-none");
    }
    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };
    const handleAddclass = (evnt) => {
        var element = document.getElementById("instruction");
        element.classList.add("ins-dsp-none");
        setConfirmPasswordError("");
    }
    const handlePwdGenerate = (e) => {
        e.preventDefault();
        document.getElementById("showPassword").click();
        if (user?.userpwd?.length > 0) {
            if (passwordInput?.password == "") {
                setError("Please Enter Password");
                setTimeout(function () { setError("") }, 3000);
            } else if (passwordInput?.confirmPassword == "") {
                setError("Please Enter Confirm Password");
                setTimeout(function () { setError("") }, 3000);
            } else if (passwordInput?.oldPassword == "") {
                setError("Please Enter Old Password");
                setTimeout(function () { setError("") }, 3000);
            } else if (passwordInput?.oldPassword == passwordInput?.password) {
                setError("old and new passwords are must not be same");
                setTimeout(function () { setError("") }, 3000);
            }
            else if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password does not match");
                setTimeout(() => setError(""), 4000);
            } else if (!limit || !upper || !special || !lower || !number) {
                setError("Please enter the password as per the instructions");
                setTimeout(() => setError(""), 4000);
            } else {
                pwdGenerate();
            }
        } else {
            if (passwordInput?.password == "") {
                setError("Please Enter Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput?.confirmPassword == "") {
                setError("Please Enter Confirm Password");
                setTimeout(function () { setError("") }, 3000);

            } else if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password does not match");
                setTimeout(() => setError(""), 4000);
            } else if (!limit || !upper || !special || !lower || !number) {
                setError("Please enter the password as per the instructions");
                setTimeout(() => setError(""), 4000);
            } else {
                pwdGenerate();
            }
        }
    };
    const handleConfirm = (evnt) => {
        const passwordInputFieldName = evnt.target.name;
        if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password")) {
        }
    }
    const pwdGenerate = async () => {
        console.log("Processing password change...");
        const clientid = localStorage.getItem('clientid');
        const token = localStorage.getItem('token');
        try {
            setIsResendLoading(true)
            const isChangePwd = user?.userpwd?.length > 0;
            const payload = {
                emailid: user.emailid,
                password: passwordInput.password,
                ...(isChangePwd && { oldPassword: passwordInput.oldPassword })
            };
            const response = await axios.post(
                `${lambda}/setPassword?appname=${appname}&clientid=${clientid}`,
                payload
            );
            if (response.data.result === "old password is wrong") {
                setIsResendLoading(false)
                setError("Old password is incorrect");
                setTimeout(() => setError(""), 4000);
            } else if (response.data.result === "password updated successfully") {
                setIsResendLoading(false)
                toast.success(user?.userpwd ? 'Password changed successfully.' : 'Password created successfully.', {
                    position: "bottom-center",
                    title: 'Success!'
                });
                setPasswordInput({
                    password: '',
                    confirmPassword: '',
                    oldPassword: ''
                })
                fetchUserData()
            } else {
                setIsResendLoading(false)
                setError("Something went wrong. Please try again.");
            }
            setActiveLoad("");
        } catch (error) {
            setIsResendLoading(false)
            setActiveLoad("");
            setError("Something went wrong. Please try again.");
        }
    };
    return (
        <div className="inner-body">
            <InsideHeader flag={true} />
            <ToastContainer
                position="bottom-right"
            />
            <div className="my-profile">
                <div className="banner-section">
                    <img alt="cover" className="banner-img" src={`${image}images/app/images/profile-bg.png`} />
                </div>
                <div className="cards-section">
                    <div className="left-side">
                        <div className="left-side-section">
                            <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#account" role="tab">
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Account Settings</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#password" role="tab">
                                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                        <span className="d-none d-sm-block">Password Settings</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content pt-15 text-muted">
                                <div className="tab-pane active" id="account" role="tabpanel">
                                    <div className="form-set">
                                        <div className="form_section">
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Name </label>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                value={user.name}
                                                                onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                                onFocus={handleFocus}
                                                                className="form-control"
                                                            />
                                                            {errors.name && <div className="text-danger">{errors.name}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Email </label>
                                                            <input
                                                                type="email"
                                                                name="personalemail"
                                                                value={user.emailid}
                                                                disabled
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Phone number </label>
                                                            <input
                                                                type="text"
                                                                name="personalphone"
                                                                value={user.contactno}
                                                                onChange={(e) => {
                                                                    const onlyDigits = e.target.value.replace(/\D/g, "");
                                                                    setUser({ ...user, contactno: onlyDigits, personalphone: onlyDigits });
                                                                }}
                                                                onFocus={handleFocus}
                                                                className="form-control"
                                                            />
                                                            {errors.personalphone && <div className="text-danger">{errors.personalphone}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn_outline gradietn_btn mr-10" disabled={isDisabled}
                                                        >{activeLoad && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane password" id="password" role="tabpanel">
                                    <div className="form-set">
                                        <div className="form_section">
                                            <div className="row">
                                                {user?.userpwd?.length > 0 &&
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Old Password</label>
                                                            <input
                                                                type={passwordShown ? "text" : "password"}
                                                                name="oldPassword"
                                                                value={passwordInput.oldPassword}
                                                                onChange={handlePasswordChange}
                                                                className="form-control capitalize-text"
                                                            />
                                                            <p className="text-danger">{oldPasswordError}</p>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>New Password</label>
                                                        <input
                                                            type={passwordShown ? "text" : "password"}
                                                            name="password"
                                                            value={passwordInput.password}
                                                            onChange={handlePasswordChange}
                                                            onKeyUp={handleValidation}
                                                            onBlur={(e) => { handleAddclass(e) }}
                                                            onFocus={(e) => { handleRemoveclass(e) }}
                                                            className="form-control"
                                                        />
                                                        <p className="text-danger">{passwordError}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-1">
                                                        <label>Confirm Password</label>
                                                        <input
                                                            type={passwordShown ? "text" : "password"}
                                                            name="confirmPassword"
                                                            value={passwordInput.confirmPassword}
                                                            onChange={handlePasswordChange}
                                                            onKeyUp={handleConfirm}
                                                            onBlur={(e) => { handleAddclass(e) }}
                                                            onFocus={(e) => { handleRemoveclass(e) }}
                                                            className="form-control"
                                                        />
                                                        <p className="text-danger">{confirmPasswordError}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-1">
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            id="showPassword"
                                                            checked={passwordShown}
                                                            onChange={() => setPasswordShown(!passwordShown)}
                                                        />
                                                        <label className="mb-0 ms-1">   Show Password</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <button
                                                        onClick={handlePwdGenerate}
                                                        className="btn_outline gradietn_btn mr-10"
                                                    >
                                                        {isResendLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                                        {user?.userpwd?.length > 0 ? 'Change Password' : 'Create Password'}
                                                    </button>
                                                    {error && <p className="text-danger">{error}</p>}
                                                    <div className='account-page'>
                                                        <div className="create-password-instruction ins-dsp-none" id="instruction">
                                                            <p className="error" id="err">{lower ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain a lower case letter</p>
                                                            <p className="error" id="err1"> {upper ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain an upper case letter</p>
                                                            <p className="error" id="err2">  {number ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain a number</p>
                                                            <p className="error" id="err3"> {special ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain a special character or a space</p>
                                                            <p className="error" id="err4"> {limit ? <span className="material-symbols-outlined">
                                                                check
                                                            </span> : <span className="material-symbols-outlined">
                                                                close
                                                            </span>} Password must contain at least 8 characters</p>
                                                        </div>
                                                    </div> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
                    </div>
                    <div className="right-side">
                        <div className="avatar-img-block">
                            <p>{user?.name && user?.name.substring(0, 2).toUpperCase()}</p>
                        </div>
                        <div className="avatar-text-block">
                            <h6 className="username">{user?.name}</h6>
                            <p>{user?.planType && user?.planType.toUpperCase() || ""}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MyAccount;