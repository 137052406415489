import React, { useState } from 'react';
import InsideHeader from './InsideHeader';
import { useNavigate } from 'react-router-dom';

const SubscriptionPlan = ({ handleSubmit }) => {
    const [storage, setStorage] = useState(100); // Default to 100GB
    const [planType, setPlanType] = useState('monthly'); // Default to monthly
    const [totalAmount, setTotalAmount] = useState(100); // Default amount for 100GB monthly

    const navigate = useNavigate();
    const monthlyCostPer100GB = 100; // INR per 100GB for monthly

    // Handle storage change with slider
    const handleStorageChange = (e) => {
        const storageAmount = Number(e.target.value);
        setStorage(storageAmount);
        calculateAmount(storageAmount, planType);
    };

    const handlePlanTypeChange = (selectedPlan) => {
        setPlanType(selectedPlan);
        calculateAmount(storage, selectedPlan);
    };

    // Calculate the total amount
    const calculateAmount = (storageAmount, plan) => {
        const cost = (storageAmount / 100) * monthlyCostPer100GB;
        if (plan === 'monthly') {
            setTotalAmount(cost);
        } else if (plan === 'yearly') {
            const yearlyCost = cost * 12;
            const discount = yearlyCost * 0.1; // 10% discount
            setTotalAmount(yearlyCost - discount);
        }
    };

    // Handle form submission
    const handleFormSubmit = () => {
        const today = new Date();
        const endDate = new Date();
        console.log("planType",planType)
        if (planType === 'monthly') {
            endDate.setDate(today.getDate() + 30);
        } else {
            endDate.setFullYear(today.getFullYear() + 1);
        }

        const payload = {
            storage,
            planType:planType,
            totalAmount: totalAmount,
            totalStorage: storage * 1073741824,
            startDate: today.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0],
        };
       navigate("/payment", { state: { totalAmount: totalAmount, payload } });
    };

    return (
        <div id="pageTop" className="page-top">
    <InsideHeader id="insideHeader" flag={true} />
    <div id="rightSection" className='right-section subscription'>
        <div id="innerBody" className="inner-body">
            <div id="subscriptionPlan" className="subscription-plan">
                <h2 id="upgradeStorageHeading">Upgrade your storage</h2>
                {/* <p>Lorem Ipsum dolor semit</p> */}
                <div id="billingType" className="billing_type">
                    <p id="selectBillingText">Select Billing type</p>
                    <div id="selectBillingButtons" className="select_billing">
                        <button
                            id="monthlyPlanButton"
                            className={`btn ${planType === 'monthly' ? 'select_btn' : ''}`}
                            onClick={() => handlePlanTypeChange('monthly')}
                        >
                            Monthly
                        </button>
                        <button
                            id="yearlyPlanButton"
                            className={`btn yearly ${planType === 'yearly' ? 'select_btn' : ''}`}
                            onClick={() => handlePlanTypeChange('yearly')}
                        >
                            Yearly <span id="yearlyDiscountText">save 10%</span>
                        </button>
                    </div>
                </div>
                <div id="storageRange" className="storage_range">
                    <div id="selectStorage" className="select_storage">
                        <span id="cloudIcon" className="material-symbols-outlined">cloud</span>
                        <p id="selectStorageText">Select Storage</p>
                    </div>
                    <div id="storageInput" className="storage_input">
                        <input
                            id="storageRangeInput"
                            type="range"
                            min="100"
                            max="1024"
                            step="100"
                            value={storage}
                            className='form-control'
                            onChange={handleStorageChange}
                        />
                    </div>
                    <div id="storagePoints" className="storage_point">
                        <p id="storage100GB">100GB</p>
                        <p id="storage500GB">500GB</p>
                        <p id="storage1TB">1TB</p>
                    </div>
                </div>
        
                <h3 id="priceHeading" className="total">Price</h3>
                <div id="priceBlock" className='price_block'>
                    {storage} GB <span id="priceAmount" className='price'>₹{totalAmount}</span>
                </div>
               
                <div id="bottomBar" className="bottom_bar">
                    <button id="proceedButton" className="btn gradietn_btn rounded-pill" onClick={handleFormSubmit}>
                        Proceed to Buy
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

    );
};

export default SubscriptionPlan;
