import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ComingSoon from './ComingSoon';
import Footer from './Footer';
let { appname, lambda } = window.app;
const Dashboard = () => {
  const [image, setImg] = useState('');
  const [dashboardResult, setDashboardResult] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);


      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
    fetchDashboardData()
  }, []);
  const fetchDashboardData = async () => {

    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.post(`${lambda}/dashboard?appname=${appname}&clientid=${clientid}`);
      if (response.status === 200 && response.data?.result) {
        setDashboardResult(response.data.result);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
    }
  };
  const handlealbum = () => {
    navigate('/albums');
  }
  return (
    <div>
      <div id="page-top">
        <Navbar />
        {/* <div className='right-section'> */}
        <div className="inner-body">
          <div className="inner-wrapper">
            <Sidebar />
            <div className='right-section'>
              <div className="page-title">
                <div className='d-flex align-items-center'>
                  <h1 className="sm-heading lite-text">Dashboard</h1>
                  {/* <button className="btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0  ml-15" >
                    <span className="d-flex align-items-center">
                      <span>Create</span>
                    </span>
                  </button> */}
                </div>
                <div className="d-flex align-items-center">
                  {/* <label className="form-label">Sort By</label>
                  <select className="form-select" name="sortby">
                    <option value="">Select</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select> */}
                </div>
              </div>
              <div className="content-block Dashboard">
              
            
              <div className="row">

              <div className="col-md-4">
              <div className="card rounded-2 full-ht total_albums">
                  <div className="card-body">
                  <div className="d-flex justify-content-between line align-items-center card-section">
                  <h3 className="sm-heading bold-txt lite-text font-big-left">Total Albums</h3>
                  <h3 className="sm-heading bold-txt lite-text font-big-right">{dashboardResult ? dashboardResult?.totalAlbums : 0 }</h3>
                  </div>
                  
                  <div className="summary-section">

                  <div className="d-flex justify-content-between mt-2 mt-1 align-items-center">
                  <h2 className="sm-heading bold-txt lite-text m-heading">Summary</h2>
                  <h2 className="sm-heading bold-txt lite-text m-2"> </h2>
                  </div>

                  {/* <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Views</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">06</h2>
                  </div> */}

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Sharing</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">{dashboardResult ? dashboardResult?.sharesCount : 0 }</h2>
                  </div>

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Photos</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">{dashboardResult ? dashboardResult?.photos : 0 }</h2>
                  </div>

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Videos</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">{dashboardResult ? dashboardResult?.videos : 0 }</h2>
                  </div>

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Devices</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">{dashboardResult ? dashboardResult?.devicesCount : 0 }</h2>
                  </div>

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <button className="small-btn"  onClick={() => handlealbum()}>Go to Albums</button>
                  </div>

                  </div>

                  </div>
                </div>
              </div>
              
              <div className="col-md-4">
              <div className="card half-ht mb-2 total_images">
              <div className="card-body">
                  <div className="d-flex justify-content-between line align-items-center card-section">
                  <h3 className="sm-heading bold-txt lite-text font-big-left">Total Images</h3>
                  <h3 className="sm-heading bold-txt lite-text font-big-right">{dashboardResult ? dashboardResult?.totalImages : 0 }</h3>
                  </div>

                  <div className="summary-section">
                  <div className="d-flex justify-content-between mt-2 align-items-center">
                      <p className="sm-heading lite-text m-0">Storage</p>
                      <h2 className="sm-heading bold-txt lite-text m-2">{dashboardResult ? dashboardResult?.imageStorage : 0 }</h2>
                    </div>

                  {/* <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Views</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">06</h2>
                  </div>

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <button className="small-btn">Go to Images</button>
                  </div> */}

                  </div>

              </div>
              </div>

              <div className="card half-ht mb-2 total_images">
              <div className="card-body">
                  <div className="d-flex justify-content-between line align-items-center card-section">
                  <h3 className="sm-heading bold-txt lite-text font-big-left">Total Videos</h3>
                  <h3 className="sm-heading bold-txt lite-text font-big-right">{dashboardResult ? dashboardResult?.totalVideos : 0 }</h3>
                  </div>
                  
                  <div className="summary-section">
                  <div className="d-flex justify-content-between mt-2 align-items-center">
                      <p className="sm-heading lite-text m-0">Storage</p>
                      <h2 className="sm-heading bold-txt lite-text m-2">{dashboardResult ? dashboardResult?.videostorage : 0 }</h2>
                    </div>
                  {/* <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="sm-heading lite-text m-0">Views</p>
                  <h2 className="sm-heading bold-txt lite-text m-2">06</h2>
                  </div>

                  <div className="d-flex justify-content-between mt-2 align-items-center">
                  <button className="small-btn">Go to Videos</button>
                  </div> */}
                  </div>

              </div>
              </div>
              <div className="card half-ht mb-2 storage_blcok">
              <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center card-section">
                  <h3 className="sm-heading bold-txt lite-text font-big-left storage">Storage</h3>
                  <h3 className="sm-heading bold-txt lite-text font-big-right"> </h3>
                  </div>
                  
                  <div className="summary-section">
                  <div className="d-flex justify-content-between m-2 align-items-center">
                  <p className="sm-heading lite-text m-0"> </p>
                  <p className="sm-heading lite-text m-0">{dashboardResult ? dashboardResult?.totalStorage : 0 }</p>
                  </div>

                  <div className="storage-progress">
                  <div className="storage-bar">
                  <p className="sm-heading lite-text m-0 d-flex justify-content-center align-items-center"><span className="material-symbols-outlined full-storage-dot">
                  fiber_manual_record </span>Full Storage</p>
                  <div className="video-bar">
                  <div className="storage-progress-bar-1">
                    <p className="sm-heading lite-text m-0 d-flex justify-content-center align-items-center"><span className="material-symbols-outlined video-dot">
                  fiber_manual_record </span>Videos</p>
                  </div>
                  </div>

                  <div className="image-bar">
                  <div className="storage-progress-bar-2">
                  <p className="sm-heading lite-text m-0 d-flex justify-content-center align-items-center"><span className="material-symbols-outlined image-dot">
                  fiber_manual_record </span>Images</p>
                  </div>
                  </div>
                  
                  </div>
                  </div>
                  </div>
                  
              </div>
              </div>
              </div>
              {dashboardResult &&  dashboardResult.transactionHistory && dashboardResult.transactionHistory.length > 0 &&
              <div className="col-md-4">

              <div className="card half-ht">
              <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center card-section">
                  <h3 className="sm-heading bold-txt lite-text font-big-left storage">Transactions</h3>
                  <h3 className="sm-heading bold-txt lite-text font-big-right"> </h3>
                  </div>

                  <div className="summary-section">
                  <div class="table-responsive">
                                <table class="table db_table">

                                    <thead class="table-light">
                                        <tr>
                                            <th width="55%">Transaction Id</th>
                                            <th width="20%">Storage</th>
                                            <th width="20%">Plan Type</th>
                                            <th width="25%" className="text-right">Amount</th>
                                           </tr>
                                    </thead>
                                    {dashboardResult &&  dashboardResult.transactionHistory && dashboardResult.transactionHistory.map((item) => {
                                      return (
                                    <tbody>
                                        <tr>
                                            <td>{item?.transactionId}</td>
                                            <td>{item?.storage}</td>
                                            <td>{item?.planType}</td>
                                            <td class="text-right">{item?.payAmount}</td>
                                            
                                        </tr>
                                    </tbody>
                                     );
                                    })}
                                </table>
                            </div>
                  </div>
              </div>
              </div>

              </div>}
              
              </div>

                 
                  
                </div>

                <Footer/>
            </div>


          </div>
        </div>
        {/* <Footer/> */}
      </div>

    </div>

  )
}
export default Dashboard;