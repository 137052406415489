import React from 'react';
// import ComingSoon from './ComingSoon';
import InsideHeader from './InsideHeader';
import Footer from './Footer';
let { appname, lambda } = window.app;

const About = () => {

  return (
    // <ComingSoon image={image}/>
    <div id="page-top">
    <InsideHeader flag={true} />

    <div id="inner-body" className="inner-body contact_us about_us">

        <div id="breadcrumb-area" className="breadcrumb-area-bg bg_image">
            <div id="breadcrumb-container" className="container">
                <div id="breadcrumb-row" className="row">
                    <div id="breadcrumb-col" className="col-lg-12">
                        <div id="breadcrumb-inner" className="bread-crumb-area-inner">

                            <div id="breadcrumb-bottom-title" className="bottom-title">
                                <h1 className="title">About Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="about-history" className="about-top-history-information rts-section-gap">
            <div id="history-container" className="container">
                <div id="history-row" className="row">
                    <div id="history-col-left" className="col-lg-4 col-md-4">
                        <div id="history-title-project" className="title-style-left">
                            <div id="history-pre-title-project" className="pre-title-area">
                                <img src=" https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about_bar.png" alt="about" />
                                <span className="pre-title">Our Project</span>
                            </div>
                        </div>
                        <div id="history-title-who" className="title-style-left out_mission">
                            <div id="history-pre-title-who" className="pre-title-area">
                                <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about_bar.png" alt="about" />
                                <span className="pre-title">Who We Are</span>
                            </div>
                        </div>
                        <div id="history-arrow" className="about-left-history pl--50 pt--30">
                            <img src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about_arrow.png" alt="about" />
                        </div>
                        <div id="history-image-left" className="history-image-left mt--55 rts-reveal-one">
                            <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about-first-right2.png" alt="about" />
                        </div>
                    </div>
                    <div id="history-col-right" className="col-lg-8 col-md-8 mt_sm--50">
                        <div id="history-right-inner" className="history-right-area-inner">
                            <p id="welcome-text" className="disc rts-slide-up">
                                Welcome to shareclix, a platform designed to celebrate life’s moments by making it easy to upload, organize, and share your personal photos and videos with friends and family. Whether it’s your latest travel adventure, a cherished family gathering, or everyday moments, we provide a safe and seamless space to preserve your memories and share them with the people who matter most.
                            </p>
                            <p id="mission-text" className="disc rts-slide-up our_mission_simple">
                                shareclix is a proud product of Sanchan Info Solutions Private Ltd, a global leader in innovative digital solutions. With over 15 years of expertise, Sanchan Info has built a reputation for excellence, delivering cutting-edge solutions across industries such as OTT applications, Real Estate, e-Commerce, Cloud Migration, AWS, Digital Transformation Services, and Mobile and Smart TV apps.
                            </p>

                            <div id="history-right-image" className="history-right-img mt--80 rts-reveal-one">
                                <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/about-first-right1.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="mission-section" className="rts-team-area rts-section-gap">
            <div id="mission-container" className="container">
                <div id="mission-row" className="row">
                    <div id="mission-col" className="col-lg-12 text-center">
                        <div id="mission-title" className="title-style-center">
                            <h2 className="title">Our Mission</h2>
                            <p className="disc">
                                At Sanchan Info Solutions Private Ltd, our mission is simple<br />To help you capture, cherish, and connect.<br />We aim to provide an intuitive and secure platform where your memories can thrive, allowing you to share life's most meaningful moments with ease and creativity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="why-choose-us" className="our-working-process rts-section-gap">
            <div id="choose-us-container" className="container">
                <div id="choose-us-row-header" className="row">
                    <div id="choose-us-col-header" className="col-lg-12">
                        <div id="choose-us-title-text" className="title-process-stock-text">
                            <h2 className="stock-text-1">
                                WHY CHOOSE US?
                            </h2>
                        </div>
                    </div>
                </div>
                <div id="choose-us-row" className="row g-5 separetor-process-top rts-slide-up">
                    <div id="choose-us-item-1" className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-0 pr-10">
                        <div id="seamless-experience" className="single-working-prcess-one seamless_experience">
                            <div id="seamless-inner" className="inner">
                                <span>01</span>
                                <h4 className="title">Seamless Experience</h4>
                                <p className="disc">
                                    Our user-friendly interface makes uploading, organizing, and sharing content effortless.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="choose-us-item-2" className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-10 pr-10">
                        <div id="advance-technology" className="single-working-prcess-one advance_technology">
                            <div id="advance-inner" className="inner">
                                <span>02</span>
                                <h4 className="title">Advanced Technology</h4>
                                <p className="disc">
                                    Built using cutting-edge solutions from Sanchan Info Solutions Private Ltd, shareclix.com is powered by expertise in cloud migration, AWS, and mobile app development.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="choose-us-item-3" className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-10 pr-10">
                        <div id="global-reliability" className="single-working-prcess-one global_reality">
                            <div id="global-inner" className="inner">
                                <span>03</span>
                                <h4 className="title">Global Reliability</h4>
                                <p className="disc">
                                    With roots in the USA and India, we bring the best of Global Innovation to your fingertips.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="choose-us-item-4" className="col-lg-3 col-md-4 col-sm-6 col-12 pt--50 pt_sm--30 pl-10 pr-0">
                        <div id="privacy-security" className="single-working-prcess-one privacy_security">
                            <div id="privacy-inner" className="inner">
                                <span>04</span>
                                <h4 className="title">Privacy and Security</h4>
                                <p className="disc">
                                    Your memories are safe with us, thanks to robust Privacy Settings and Secure Data Storage.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div id="service-area-start" className="service-area-start rts-service-area rts-section-gap bg-inner-light">
    <div id="service-container" className="container">
        <div id="service-row" className="row">
            <div id="service-col-lg-12" className="col-lg-12">
                <div id="service-full-top-wrapper" className="service-full-top-wrapper rts-slide-up">

                    <div id="service-title-style-left" className="title-style-left">
                        <h2 className="title">The Sanchan Info<br /> Solutions Advantage</h2>
                    </div>

                    <p id="service-disc" className="disc mb-0">
                        For over 15 years, Sanchan Info Solutions Pvt Ltd. has been at the forefront of digital innovation, serving clients worldwide across multiple domains
                    </p>
                    <a id="view-all-services-link" href="#" className="rts-read-more-circle-btn under-line">
                        <span className="material-symbols-outlined"> north_east </span>
                        <p>View All Services</p>
                    </a>
                </div>
            </div>
        </div>

        <div id="service-row-secondary" className="row mt--20 g-5 rts-slide-up">
            <div id="ott-apps" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div id="single-service-style-five-1" className="single-service-style-five">
                    <div id="ott-icon-area" className="icon-area">
                        <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/ott.png" alt="" />
                    </div>
                    <div id="ott-body" className="body">
                        <a href="#">
                            <h6 className="title">OTT Applications</h6>
                        </a>
                        <p className="disc">
                            Expertise in building top-tier streaming platforms.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>
            </div>
            <div id="real-estate-apps" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div id="single-service-style-five-2" className="single-service-style-five">
                    <div id="real-estate-icon-area" className="icon-area">
                        <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/real-esatate.png" alt="" />
                    </div>
                    <div id="real-estate-body" className="body">
                        <a href="#">
                            <h6 className="title">Real Estate Apps</h6>
                        </a>
                        <p className="disc">
                            Innovative solutions for real estate management and transactions.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>
            </div>
            <div id="ecommerce-apps" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div id="single-service-style-five-3" className="single-service-style-five">
                    <div id="ecommerce-icon-area" className="icon-area">
                        <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/e-commerce.png" alt="" />
                    </div>
                    <div id="ecommerce-body" className="body">
                        <a href="#">
                            <h6 className="title">E-commerce Apps</h6>
                        </a>
                        <p className="disc">
                            Custom platforms tailored for seamless online shopping experiences.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>
            </div>
            <div id="cloud-aws" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div id="single-service-style-five-4" className="single-service-style-five">
                    <div id="cloud-icon-area" className="icon-area">
                        <img className="rts-reveal-image-one" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/aws.png" alt="" />
                    </div>
                    <div id="cloud-body" className="body">
                        <a href="#">
                            <h6 className="title">Cloud Migration & AWS</h6>
                        </a>
                        <p className="disc">
                            Scalable, reliable cloud solutions for businesses of all sizes.
                        </p>
                        <a href="#">Read More <span className="material-symbols-outlined">chevron_right</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="join-today" className="rts-team-area rts-section-gap join_today">
    <div id="join-container" className="container">
        <div id="join-row" className="row">
            <div id="join-col-lg-12" className="col-lg-12 text-center">
                <div id="join-title-style-center" className="title-style-center">
                    <h2 className="title">Join Us Today!</h2>
                    <p className="disc">
                        Start uploading, organizing, and sharing your stories on shareclix.app—a platform built with love and expertise by Sanchan Info Solutions Pvt Ltd.. Together, let’s turn your moments into memories and your memories into stories that last a lifetime.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="footer-strip" className="footer_strip">
    <div id="footer-container" className="container">
        <div id="footer-content">
            <h4>Have questions? Get in touch at [contact@shareclix.com]. Let’s make memories, together.</h4>
        </div>
    </div>
</div>



<Footer />
</div>
    </div>

  );
};

export default About;
