import React from 'react';
// import ComingSoon from './ComingSoon';
import InsideHeader from './InsideHeader';
import Footer from './Footer';
let { appname, lambda } = window.app;

const Privacy = () => {
  return (
    // <ComingSoon image={image}/>
    <div className='inner_page' id="innerPage">
  <InsideHeader flag={true} id="insideHeader" />
  <div className='content_block' id="contentBlock">
    <div className='content' id="content">
      <h1 id="userAgreementTitle">The Shareclix User Agreement</h1>

      <h3 id="agreementDate">March 2024</h3>
      <p id="introductionText">
        Welcome to Shareclix ("Event Sharing"). These Terms of Use ("Terms") govern your access to and use of Shareclix, including its mobile applications, websites, and related services (collectively referred to as the "Platform"). By accessing or using the Platform, you agree to comply with and be bound by these Terms. If you do not agree, please do not use the Platform.
      </p>
      
      <h4 id="eligibilityTitle">Eligibility:</h4>
      <ul id="eligibilityList">
        <li id="eligibilityAge">You must be at least 13 years of age (or the minimum age required in your jurisdiction) to use the Platform.</li>
        <li id="eligibilityInfo">By using the Platform, you represent and warrant that all information you provide is accurate, current, and complete.</li>
      </ul>

      <h4 id="accountRegistrationTitle">Account Registration:</h4>
      <ul id="accountRegistrationList">
        <li id="accountRegistrationRequirement">To access certain features, you must create an account.</li>
        <li id="accountConfidentiality">You are responsible for maintaining the confidentiality of your login credentials.</li>
        <li id="accountSharing">You agree not to share your account with others or use another user's account.</li>
      </ul>

      <h4 id="userContentTitle">User Content:</h4>
      <ul id="userContentList">
        <li id="userContentLicense">By uploading photos, videos, or other materials ("User Content") to the Platform, you grant Shareclix a worldwide, non-exclusive, royalty-free license to host, store, display, and share your content solely to operate and improve the Platform.</li>
        <li id="userContentOwnership">You retain ownership of your User Content but are responsible for ensuring that it complies with these Terms and applicable laws.</li>
      </ul>

      <h4 id="prohibitedActivitiesTitle">Prohibited Activities:</h4>
      <p id="prohibitedActivitiesIntro">You agree not to use the Platform to:</p>
      <ul id="prohibitedActivitiesList">
        <li id="illegalContent">Post content that is illegal, obscene, hateful, or infringes on third-party rights.</li>
        <li id="harassment">Harass, stalk, or harm others.</li>
        <li id="maliciousContent">Share content that contains viruses or malicious code.</li>
        <li id="fraudulentActivities">Engage in spamming, phishing, or other fraudulent activities.</li>
      </ul>

      <h4 id="privacyTitle">Privacy:</h4>
      <p id="privacyText">Your use of the Platform is subject to our Privacy Policy, which describes how we collect, use, and share your personal information. Please review it carefully.</p>

      <h4 id="intellectualPropertyTitle">Intellectual Property:</h4>
      <ul id="intellectualPropertyList">
        <li id="intellectualPropertyRights">All intellectual property on the Platform (excluding User Content) belongs to Shareclix or its licensors.</li>
        <li id="intellectualPropertyRestrictions">You may not copy, modify, distribute, or create derivative works based on our content without written permission.</li>
      </ul>

      <h4 id="terminationTitle">Termination:</h4>
      <ul id="terminationList">
        <li id="accountSuspension">We reserve the right to suspend or terminate your account if you violate these Terms or if your actions may harm the Platform or its users.</li>
        <li id="accountDeletion">You may terminate your account at any time by contacting us or following the account deletion instructions on the Platform.</li>
      </ul>

      <h4 id="disclaimersTitle">Disclaimers and Limitation of Liability:</h4>
      <ul id="disclaimersList">
        <li id="noWarranty">The Platform is provided "as is" without warranties of any kind.</li>
        <li id="noLiability">We are not liable for any damages, loss of data, or harm resulting from your use of the Platform.</li>
      </ul>

      <h4 id="governingLawTitle">Governing Law and Dispute Resolution:</h4>
      <p id="governingLawText">These Terms are governed by the laws of India, Andhra Pradesh, and any disputes arising from these Terms will be resolved in the courts of [Your Jurisdiction].</p>

      <h4 id="changesToTermsTitle">Changes to These Terms:</h4>
      <p id="changesToTermsText">We may update these Terms from time to time. Significant changes will be communicated to you, and continued use of the Platform constitutes acceptance of the updated Terms.</p>

      <h4 id="contactUsTitle">Contact Us:</h4>
      <p id="contactEmailText">If you have any questions about these Terms, please contact us at:</p>
      <p id="contactEmail">Email: prakash@sanchaninfo.com</p>
      <p id="contactAddress">Address: Flat No 101, Sri RK Homes, Marripalem VUDA Layout, Visakhapatnam, India - 530016.</p>
    </div>
  </div>
  <Footer id="footer" />
</div>


  );
};

export default Privacy;
