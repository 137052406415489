import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from './Footer';
let { appname, lambda } = window.app;

const Home = () => {
    const navigate = useNavigate();
    const [image, setImg] = useState('');
    const [svgImage, setSvgImg] = useState('')
    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);
                setSvgImg(window.site.common.resourcesCloudfront);
            } else {
                setTimeout(checkSiteObject, 50); // Check again after a short delay
            }
        };

        checkSiteObject();
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, []);

    const handlemenu = (menu) => {
        navigate(menu);
    };
    const d = new Date();
    let year = d.getFullYear();
    return (
        <>
           <div>
    <header id="header-sticky" className="header-style-two header--sticky">
        <div className="header-wrapper-main" id="header-wrapper-main">
            <a href="#" id="logo-link" className="logo">
                <img src={`${image}images/app/images/logo-white1.png`} alt="logo" id="logo" />
            </a>

            <div id="header-right" className="header-right">
                <div id="nav-area-center" className="nav-area-center">
                    <nav id="main-navigation" className="navigation">
                        <ul id="parent-ul" className="parent-ul">
                            <li id="about-menu-item" className="has-dropdown with-megamenu">
                                <Link id="about-link" className="nav-link" to="/about">About</Link>
                            </li>

                            <li id="signin-menu-item" className="has-dropdown">
                                <Link id="signin-link" className="nav-link" to="/signin">Sign In</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <Link id="get-started-btn" className="rts-btn btn-border" to="/fileupload">
                    Get Started <span className="material-icons icon"> east </span>
                </Link>

                <div id="action-area" className="action-area">
                    <div id="search" className="search">
                        <i id="search-icon" className="fa-light fa-magnifying-glass"></i>
                    </div>
                    <div id="menu-btn" className="menu-icon">
                        <svg id="menu-svg" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="menu-path" d="M0 1.34375C0 0.886719 0.351562 0.5 0.84375 0.5H14.9062C15.3633 0.5 15.75 0.886719 15.75 1.34375C15.75 1.83594 15.3633 2.1875 14.9062 2.1875H0.84375C0.351562 2.1875 0 1.83594 0 1.34375ZM0 6.96875C0 6.51172 0.351562 6.125 0.84375 6.125H10.4062C10.8633 6.125 11.25 6.51172 11.25 6.96875C11.25 7.46094 10.8633 7.8125 10.4062 7.8125H0.84375C0.351562 7.8125 0 7.46094 0 6.96875ZM5.90625 13.4375H0.84375C0.351562 13.4375 0 13.0859 0 12.5938C0 12.1367 0.351562 11.75 0.84375 11.75H5.90625C6.36328 11.75 6.75 12.1367 6.75 12.5938C6.75 13.0859 6.36328 13.4375 5.90625 13.4375Z" fill="#30373E" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div id="swiper-banner-wrapper" className="swiper-banner-two-wrapper">
        <div id="swiper-main" className="swiper mySwiper-banner-two">
            <div id="swiper-wrapper" className="swiper-wrapper">
                <div id="swiper-slide" className="swiper-slide">

                    <div id="banner-twoa-rea-start" className="banner-twoa-rea-start bg_image banner-bg-2">
                        <div id="container" className="container">
                            <div className="row">
                                <div id="col-lg-12" className="col-lg-12">

                                    <div id="banner-inner-content" className="banner-inner-content-wrapper animated">
                                        <span id="pre-title" className="pre-title">Share Clix </span>
                                        <h1 id="banner-title" className="title ">
                                            Your memories, <span> <br /> your control.</span>
                                        </h1>
                                        <p id="banner-desc" className="disc">
                                            Create, organize, and share your event photos effortlessly.
                                        </p>
                                        <Link id="create-album-btn" to="/fileupload" className="rts-btn btn-border">Create album  <span className="material-icons icon"> east </span></Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="thumbs-swiper-wrapper" className="thumbs-swiper-main-wrapper-two">

                <div id="swiper-thumb" className="swiper swiper-banner-thumb-2">

                </div>
            </div>
        </div>
    </div>

    <div id="about-area" className="rts-about-area rts-section-gap bg-light">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div id="about-thumbnail" className="thumbnail-image">
                        <img src={`${image}images/app/images/003.png`} alt="about" id="about-img"/>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div id="about-inner" className="about-inner-two-wrapper animated">
                        <div id="about-title" className="title-left-style-two">
                            <div id="pre-title-about" className="pre-title">
                                <span className="pre-title">About US</span>
                                <img src={`${image}images/app/images/02.png`} alt="service" id="about-service-img"/>
                            </div>

                            <h2 id="about-heading" className="title quote">
                                Welcome to Share Clix
                            </h2>
                            <h6 id="about-subheading" className="title">Share Your Special Moments Effortlessly!</h6>
                        </div>
                        <p id="about-desc" className="disc">
                            Capture the joy of your special moments and share them with the ones who matter most!
                            Whether it’s a Birthday party, Engagement, Wedding, Family trip, or photoshoot, our platform
                            allows you to easily upload, organize, and share your memories through a personalized
                            webpage or even on your Smart TV.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="service-area" className="rts-service-area rts-section-gap">
        <div className="container">
            <div className="row ">
                <div id="service-title" className="col-lg-12">
                    <div className="title-area-multiple-elements">
                        <div id="service-title-left" className="title-left-style-two">
                            <div id="service-pre-title" className="pre-title">
                                <span className="pre-title">Services</span>
                                <img src={`${image}images/app/images/02.png`} alt="service" id="service-img"/>
                            </div>
                            <h2 id="service-heading" className="title quote">
                                How it works
                            </h2>
                        </div>

                        <a id="how-it-works-link" href="how-it-works.html" className="view-servce-btn">How it works <span className="material-icons icon">
                            arrow_forward_ios
                        </span></a>
                    </div>
                </div>
            </div>
            <div className="row g-5 mt--30 rts-slide-up animated">
                <div id="service-1" className="col-lg-4 col-md-6 col-sm-12">
                    <div className="singe-serice-style-two">
                        <Link to="/fileupload">
                            <img src={`${image}images/app/images/03.jpg`} alt="service" id="service-1-img"/>
                        </Link>
                        <div className="body">
                            <div className="wrapper">
                                <div className="icon-area">
                                    <div className="icon">
                                        <img src={`${image}images/app/images/uploadphotos1.svg`} id="upload-icon"/>
                                    </div>
                                    <span>01</span>
                                </div>
                                <div className="inner">
                                    <Link to="/fileupload">
                                        <h6 id="upload-title" className="title">Upload Your Photos</h6>
                                    </Link>
                                    <p className="disc">Choose from your device or cloud storage and upload photos from your
                                        latest events.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="service-2" className="col-lg-4 col-md-6 col-sm-12">
                    <div className="singe-serice-style-two">
                        <Link to="/fileupload">
                            <img src={`${image}images/app/images/0004.jpg`} alt="service" id="service-2-img"/>
                        </Link>
                        <div className="body">
                            <div className="wrapper">
                                <div className="icon-area">
                                    <div className="icon">
                                        <img src={`${image}images/app/images/createalbum1.svg`} id="create-album-icon"/>
                                    </div>
                                    <span>02</span>
                                </div>
                                <div className="inner">
                                    <Link to="/fileupload">
                                        <h6 id="create-album-title" className="title">Create Your Album</h6>
                                    </Link>
                                    <p className="disc">Organize your images into beautiful, customized albums with captions
                                        and descriptions to enhance your story.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="service-3" className="col-lg-4 col-md-6 col-sm-12 slider">
                    <div className="singe-serice-style-two">
                        <Link to="/fileupload">
                            <img src={`${image}images/app/images/0005.jpg`} alt="service" id="service-3-img"/>
                        </Link>
                        <div className="body">
                            <div className="wrapper">
                                <div className="icon-area">
                                    <div className="icon">
                                        <img src={`${image}images/app/images/Sharejoy1.svg`} id="sharejoy-icon"/>
                                    </div>
                                    <span>03</span>
                                </div>
                                <div className="inner">
                                    <Link to="/fileupload">
                                        <h6 id="sharejoy-title" className="title">Share the Joy</h6>
                                    </Link>
                                    <p className="disc">Share your album with friends and family through a private link or
                                        enjoy it on your Smart TV app for an immersive viewing experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="why-choose-us-section" className="rts-section-gap why-chooseus-section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7 pr--120 pr_sm--0">
                    <div id="why-choose-us-left" className="why-choose-us-left-two animated">
                        <div id="why-title-left" className="title-left-style-two">
                            <h2 id="why-title" className="title quote">
                                Why choose us
                            </h2>
                        </div>
                        <p className="disc">
                            Choosing interior design services offers numerous benefits. Here are a few reasons why you
                            should consider working with professional interior designers:
                        </p>
                    </div>

                    <div className="row mt--20 g-5">
                        <div id="why-choice-1" className="col-lg-6 rts-slide-up">
                            <div className="single-choose-style-two">
                                <div className="icon">
                                    <img src={`${image}images/app/images/user-friendly.png`} alt="choose_icon" />
                                </div>
                                <div className="right-content">
                                    <h6 id="user-friendly-title" className="title">User-Friendly Experience</h6>
                                    <p className="disc">
                                        Uploading, organizing, and sharing your event photos has never been easier! Our
                                        intuitive interface is designed for all users, regardless of tech-savviness.
                                        With just a few clicks, your memories are ready to share.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="why-choice-2" className="col-lg-6 rts-slide-up">
                            <div className="single-choose-style-two">
                                <div className="icon">
                                    <img src={`${image}images/app/images/02-1.svg`} alt="choose_icon" />
                                </div>
                                <div className="right-content">
                                    <h6 id="seamless-sharing-title" className="title">Seamless Sharing Options</h6>
                                    <p className="disc">
                                        Create beautiful albums and share them through custom web pages or via our Smart
                                        TV app for an immersive experience. Let your loved ones view your events with
                                        the same excitement that you experienced.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="why-choose-img" className="col-lg-5 mt_sm--50">
                    <div className="thumbnail">
                        <img src={`${image}images/app/images/06.png`} alt="service" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="cta-main-wrapper" className="rts-cta-main-wrapper rts-section-gap signup_block">
        <div className="container">
            <div className="row">
                <div id="cta-content" className="col-lg-12 animated">
                    <div className="title-style-center cta-wrapper-two">
                        <div className="pre-title-area">
                            <img src={`${image}images/app/images/02.png`} alt="about" />
                            <span className="pre-title">Create your first Album</span>
                        </div>
                        <h2 className="title quote"><span>Ready to</span> Share <br /> Your <span>Memories?</span></h2>

                        <p className="disc">
                            We know how important your privacy is. That’s why we’ve built our platform with top-tier
                            encryption and secure servers to ensure your memories are safe and only accessible to those
                            you choose to share with. Your photos are never shared or used without your consent.
                        </p>
                        <Link id="signup-btn" className="rts-btn btn-border " to="/signup">SIGN UP <span className="material-icons icon"> east </span></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer id="footer-section" />
</div>

        </>
    )
}



export default Home;
