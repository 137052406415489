import React from 'react';
// import ComingSoon from './ComingSoon';
import InsideHeader from './InsideHeader';
import Footer from './Footer';

const RefundPolicy = () => {
  return (
    // <ComingSoon image={image}/>
    <div id="innerPage" className='inner_page'>
    <InsideHeader flag={true} />
    <div id="contentBlock" className='content_block'>
      <div id="content" className='content'>
        <h1 id="refundPolicyTitle">Refund Policy</h1>

        <h3 id="effectiveDate">Effective Date: 01-01-2025</h3>
        <p id="introText">At shareclix.app, we are committed to providing a seamless and enjoyable experience for our users. If you encounter issues or are dissatisfied with our services, this refund policy outlines the conditions under which refunds may be issued.</p>
        
        <h4 id="eligibilityForRefunds">1. Eligibility for Refunds</h4>
        <p id="eligibilityRefundDetails"><b>Refunds may be provided under the following circumstances:</b></p>
        <p id="technicalIssues"><b>Technical Issues:</b><br />
        If you are unable to access or use the services due to a technical issue caused by shareclix.app that cannot be resolved within a reasonable time frame.
        </p>
        <p id="serviceFailure"><b>Service Failure:</b><br />
        If features such as photo-sharing, album creation, or storage do not perform as described, and the issue cannot be resolved after reporting it to our support team.
        </p>
        <p id="duplicateCharges"><b>Duplicate Charges:</b><br />
        If you were mistakenly charged multiple times for the same subscription or service.
        </p>
        
        <h4 id="nonRefundableCases">2. Non-Refundable Cases</h4>
        <p id="nonRefundableDetails"><b>Refunds will not be issued in the following scenarios:</b></p>
        <p id="successfulService">If the service was used successfully, and no issues were reported during the usage period.</p>
        <p id="personalPreferences">Dissatisfaction arising from personal preferences unrelated to service functionality (e.g., design, features).</p>
        <p id="subscriptionTimeUsed">Subscription payments for time already used, unless technical issues prevented full access.</p>

        <h4 id="refundTimeFrame">3. Refund Time Frame</h4>
        <p id="refundTimeDetails">Refund requests must be submitted within 14 Days of the initial payment or subscription renewal date. Requests made outside this period may not be eligible for a refund.</p>
        
        <h4 id="howToRequestRefund">4. How to Request a Refund</h4>
        <p id="refundRequestDetails"><b>To request a refund:</b></p>
        <p id="contactDetails">Contact our customer support team at contact@shaeclix.app or through www.shareclix.app/contactus.</p>
        <p id="requiredDetails"><b>Include the following details:</b> <br />
        Your account email address.<br />
        Proof of payment (e.g., receipt, transaction ID).<br />
        A detailed explanation of the issue and why you are requesting a refund.<br />
        Our team will review your request and respond within  5-7 business days.</p>

        <h4 id="refundMethod">5. Refund Method</h4>
        <p id="refundMethodDetails">Approved refunds will be issued to the original payment method used at the time of purchase.<br />
        It may take  5-10 business days for the refunded amount to reflect in your account, depending on your payment provider.</p>

        <h4 id="cancellationsAndModifications">6. Cancellations and Modifications</h4>
        <p id="cancellationDetails">Users may cancel subscriptions at any time through their account settings.<br />
        Canceling a subscription will stop future charges but will not result in a refund for the current billing period.</p>

        <h4 id="contactUs">7. Contact Us</h4>
        <p id="contactInfo">If you have questions about this refund policy or need assistance, feel free to contact us at:</p>
        <p id="contactEmail"><b>Email:</b><a href="mailto:contact@shareclix.app" target='_blank'> contact@shareclix.app</a><br />
        <b>Support Page: </b> <a href="https://www.shareclix.app/contactus" target='_blank'>www.shareclix.app/contactus</a></p>
      </div>
    </div>
    <Footer id="footer" />
</div>


  );
};

export default RefundPolicy;
