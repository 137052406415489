import React, { useState, useEffect } from 'react';


const RegionNotFound = () => {

    useEffect(() => {
        // localStorage.clear()

    }, []);


    return (
<div id="page-top">
  <div className="inner-body" id="innerBody">
    <div id="contentWrapper">
      <div id="contentBlock">
        <div className="content-block blank-page" id="blankPage">
          <div className="text-center transform-center" id="errorMessage">
            <h2 id="errorTitle">ERROR!</h2>
            <img className="defualt-img" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/default-img-latest1.png" alt="comingsoon" id="defaultImage"/>
            <p className="mt-4" id="regionUnavailableMessage">Sorry! This website is not currently available in your region</p>
            <img className="logo mt-3" src="https://d23f5g3ljiuomd.cloudfront.net/images/app/images/logo-dark1.png" alt="comingsoon" id="logoImage"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    )
}



export default RegionNotFound;
