import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';
let { appname, lambda } = window.app;

const Settings = () => {
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);


      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  return (
    <div id="mainContainer">
    <div id="pageTop">
        <Navbar id="navbar" />
        <div id="innerBody" className="inner-body">
            <div id="innerWrapper" className="inner-wrapper">
                <Sidebar id="sidebar" />
                <div id="rightSection" className='right-section'>
                    <div id="pageTitle" className="page-title">
                        <div className='d-flex align-items-center'>
                            <h1 id="settingsHeading" className="sm-heading lite-text">Settings</h1>
                        </div>
                        <div className="d-flex align-items-center">
                            {/* <label className="form-label">Sort By</label>
                            <select className="form-select" name="sortby">
                                <option value="">Select</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                            </select> */}
                        </div>
                    </div>
                    <div id="contentBlock" className="content-block blankpage">
                        <div id="comingSoonWrapper" className="text-center transform-center">
                            <img id="comingSoonImage" src={`${image}images/app/images/default-img.png`} alt="comingsoon" />
                            <h3 id="comingSoonTitle">COMING SOON</h3>
                            <p id="comingSoonMessage">We're working hard to get this page up and running. Stay tuned!</p>
                        </div>
                    </div>
                    <Footer id="footer" />
                </div>
            </div>
        </div>
    </div>
</div>


  )
}
export default Settings;