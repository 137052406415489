
import React, {  } from "react";

import { useNavigate } from "react-router";


import Modal from 'react-bootstrap/Modal';


const SessionPopup = () => {

 const navigate = useNavigate();
   

    const onConfirm = () => {
        localStorage.removeItem("token");
            localStorage.removeItem("currentSessionClientTime");
            localStorage.removeItem("clientid");
            navigate('/signin')
       
    }



    return (
        
        <>

<Modal id="sessionTerminatedModal" className="modal fade large_popup delete_popup access-denied" show={true} >
    <div id="modalBody" className="modal-body">
        <button id="closeBtn" className="close-btn" onClick={e => onConfirm()}>
            <span className="material-icons">close</span>
        </button>
        <span id="accessDeniedIcon" className="material-icons access-denied-icon">lock_clock</span>
        <h3 id="sessionTerminatedTitle">Session Terminated</h3>
        <p id="sessionTerminatedMessage">The current session has been terminated because someone has logged in another window.</p>
        <div id="popupFooter" className="popup-footer">
            <button id="okBtn" className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onConfirm()}>
                OK
            </button>
        </div>
    </div>
</Modal>



        </>
    )
}
export default SessionPopup