import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import Loader from './Loader'
import moment from 'moment';
import SessionPopup from './SessionPopup';
import Footer from './Footer';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  GabIcon,
  GabShareButton,
  HatenaIcon,
  HatenaShareButton,
  HatenaShareCount,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  OKShareCount,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  VKShareCount,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
  XIcon,
} from "react-share";
let { appname, lambda } = window.app;

const Albums = () => {
  const [image, setImg] = useState('');
  const [svgImage, setSvgImg] = useState('');
  const [albumList, setAlbumList] = useState([]);
  const navigate = useNavigate();
  const [Images, setImages] = useState([]);
  const [sourceImg, setSourceImg] = useState('');
  const [showDocAlert, setShowDocAlert] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [deletedAlbum, setDeletedAlbum] = useState(null);
  const [editAlbum, setEditAlbum] = useState(null);
  const [activeLoad, setActiveLoad] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [showShareAlbum, setshowShareAlbum] = useState(false);
  const [publicUrl, setPublicUrl] = useState(false);
  const [privateUrl, setPrivateUrl] = useState(false);
  const [ActiveAlbumItem, setActiveAlbumItem] = useState({});
  const [albumSharedImage, setAlbumSharedImage] = useState("");
  const [albumSharedTitle, setAlbumSharedTitle] = useState("");
  const [activeUrl, setActiveUrl] = useState("public");
  const [showTvInput, setShowTvInput] = useState(false); // State to show/hide TV input
  const [tvCode, setTvCode] = useState(''); // State for TV code input
  const [tvName, setTvName] = useState(''); // State for TV Name input
  const [privateName, setPrivateName] = useState(''); // State for TV Name input
  const [showSessionPopupup, setShowSessionPopupup] = useState(false);
  const [albumName, setAlbumName] = useState("");
  const [albumDate, setAlbumDate] = useState(null);
  const [cardWidths, setCardWidths] = useState({});
  const [copied, setCopied] = useState(false);
  const [shareWith, setshareWith] = useState(false);

  const handleCopy = () => {
    // Copy the text to the clipboard
    navigator.clipboard.writeText(shareUrl).then(() => {
      // Show tooltip
      setCopied(true);

      // Hide the tooltip after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };


  const handleTvSubmit = async () => {

    if (!tvName.trim()) {
      toast.error('Please enter Tv Name.');
      return;
    }

    if (!tvCode.trim()) {
      toast.error('Please enter Code.');
      return;
    }

    const tvCodeApiUrl = `${lambda}/shareLinkUpdate?appname=${appname}&appType=TV&code=${tvCode}&name=${tvName}`;

    try {
      setActiveLoad(true)
      const response = await fetch(tvCodeApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json, text/plain, */*',
        },
        body: JSON.stringify({
          albumid: ActiveAlbumItem?.albumid,
          albumName: ActiveAlbumItem?.albumName,
          status: "Approved",
          clientid: ActiveAlbumItem?.clientid,
          "thumbnail": ActiveAlbumItem?.thumbnail || ""
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setTvCode("");
        setTvName("");
        setActiveLoad(false)
        toast.success('TV code submitted successfully!');
        setshowShareAlbum(false); setShareUrl(""); setActiveUrl("public")
        console.log('TV code submitted successfully:', data);
      } else {
        throw new Error('Failed to submit TV code');
      }
    } catch (error) {
      toast.error('Error submitting the TV code!');
      console.error('Error submitting TV code:', error);
    }
  };

  const fetchAlbumInfo = async () => {
    setActiveLoad(true)
    setAlbumList([])
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/albumInfo?appname=${appname}&clientid=${clientid}&token=${token}`);
      if (response?.data?.result == "Invalid token or Expired") {
        setShowSessionPopupup(true)
      } else if (response.data.result && response.data.result.length > 0) {
        setAlbumList(response.data.result);
      }

      // setAlbumList(response.data.result);
      setActiveLoad(false)
    } catch (error) {
      setActiveLoad(false)
      console.error('Error fetching Images:', error);
    }
  };

  const shortUrl1 = async (shareUrl, type) => {
    setActiveLoad(true)
    setActiveUrl(type)
    try {
      const response = await axios.post(`${lambda}/shorturl?appname=${appname}`, { "url": shareUrl });
      setShareUrl(response?.data?.result?.url_short);
      setActiveLoad(false)
      setshowShareAlbum(true)
    } catch (error) {
      setActiveLoad(false)
      console.error('Error fetching Images:', error);
    }
  };
  const shortUrl = async (shareUrl, type) => {
    setActiveLoad(true);
    setActiveUrl(type);

    if (type === 'tv') {
      setShowTvInput(true);  // Show TV code input box
      setShareUrl(""); // Reset the shareUrl, since it's not relevant for TV code
      setActiveLoad(false);
    } else {
      setShowTvInput(false);  // Hide TV code input if not TV type
      try {
        const response = await axios.post(
          `${lambda}/shorturl?appname=${appname}`,
          { "url": shareUrl }
        );
        setShareUrl(response?.data?.result?.url_short);
        if (type === 'private') {
          setshareWith(true)
        }
        setActiveLoad(false);
        setshowShareAlbum(true);
      } catch (error) {
        setActiveLoad(false);
        console.error('Error shortening URL:', error);
      }
    }
  };

  const privateSetUrl = async (shareUrl, type) => {
    setActiveLoad(true);
    setActiveUrl(type);
    setPrivateName('')
    setshareWith(false)
    setShowTvInput(false);  // Hide TV code input if not TV type
    try {
      // const response = await axios.post(
      //   `${lambda}/shorturl?appname=${appname}`,
      //   { "url": shareUrl }
      // );
      // setShareUrl(response?.data?.result?.url_short);
      setActiveLoad(false);
      setshowShareAlbum(true);
    } catch (error) {
      setActiveLoad(false);
      console.error('Error shortening URL:', error);
    }

  };

  useEffect(() => {
    // fetchclientContentInfo();
    fetchAlbumInfo();
  }, []);
  const fetchclientContentInfo = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setActiveLoad(true)
      const response = await axios.get(`${lambda}/clientContentInfo?appname=${appname}&clientid=${clientid}&token=${token}`);
      setImages(response.data.result);
      setActiveLoad(false)
    } catch (error) {
      console.error('Error fetching Images:', error);
    }
  };

  const handleDeletePopup = (album) => {
    setDeletedAlbum(album)
    setShowDocAlert(true)

  };

  const setPrivateUrlName = (name) => {
    // console.log('naaa', name)
    let setprivateUrl = privateUrl + `?name=` + name
    shortUrl(setprivateUrl, 'private');

  }

  const handleEditPopup = (album) => {
    setEditAlbum(album)
    // console.log('album',album)

    // album?.thumbnail ? image + album?.thumbnail
    let imagePath = album?.contentids?.[0] ? album?.contentids?.[0]?.filetype?.startsWith('video/') ? album?.thumbnail ? image + album?.thumbnail : `${image}images/app/images/play-default.jpg` : `${image}${album?.thumbnail ? album?.thumbnail : album?.contentids?.[0]?.filepath}` : `${image}images/app/images/alumbsDefault.jpg`

    setAlbumName(album?.albumName)
    setAlbumDate(album?.eventDate)
    setAlbumSharedImage(imagePath)
    setShowRenamePopup(true)

  };
  const handleEditAlbum = async (item) => {
    if (!albumName.trim()) {
      toast.error('Please enter an album name.');
      return;
    }
    if (!albumDate.trim()) {
      toast.error('Please enter an event date.');
      return;
    }

    try {
      setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${lambda}/addAlbum?appname=${appname}&clientid=${clientid}&albumid=${item.albumid}`,
        {
          albumName: albumName,
          eventDate: albumDate,
          albumids: [item.albumid],
          thumbnail: item.thumbnail,
          thumbnailId: item.thumbnailId
        }
      );

      setActiveLoad(false)
      if (response.data) {


        if (response.data?.result) {

          if (response.data.result === "Same title already exists") {
            toast.error(response.data.result)
          } else {
            console.log('Images added to album:', response.data);
            setShowRenamePopup(false)
            setActiveLoad(false)
            toast.success(`Updated successfully!`);
            fetchAlbumInfo();
          }
        }
      }

      // navigate(-1);
    } catch (error) {
      setActiveLoad(false)
      console.error('Error adding images to album:', error);
      toast.error('Failed to update album name.');
    }
  };
  const handleDeleteAlbum = async (item) => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setActiveLoad(true)
      setShowDocAlert(false)
      const response = await axios.delete(`${lambda}/deleteAlbum?appname=${appname}&clientid=${clientid}&albumid=${item?.albumid}`);

      if (response.status === 200) {

        setActiveLoad(false)
        toast.success('Album deleted successfully!');
        fetchAlbumInfo();
        // Optionally, remove the album from the UI or refetch the album list here.
      } else {
        toast.error('Failed to delete the album. Please try again.');
      }

      console.log('response', response);
    } catch (error) {
      console.error('Error deleting album:', error);
      toast.error('An error occurred while deleting the album.');
    }
  };

  useEffect(() => {
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
        setSvgImg(window.site.common.resourcesCloudfront);
        setSourceImg(window.site.common.sourceCloudFront);

      } else {
        setTimeout(checkSiteObject, 50);
      }
    };
    checkSiteObject();
  }, []);

  useEffect(() => {
    const widths = {};
    document.querySelectorAll(".album-img").forEach((img) => {
      const id = img.getAttribute("data-id");
      if (id) {
        widths[id] = img.offsetWidth;
      }
    });
    setCardWidths(widths);
  }, [albumList]);

  const handleCreate = () => {
    navigate('/event');
  };

  const handleSharePopup = (album) => {
    let publicShareUrl = window.location.protocol + "//" + window.location.host + "/album/" + album?.clientid + "/" + album?.albumid + "/" + album?.public
    let privateShareUrl = window.location.protocol + "//" + window.location.host + "/album/" + album?.clientid + "/" + album?.albumid + "/" + album?.private

    let imagePath = `${image}images/app/images/alumbsDefault.jpg`
    if (album?.contentids?.[0]) {
      imagePath = album?.contentids?.[0]?.filetype?.startsWith('video/') ? `${image}images/app/images/play-default.jpg` : `${image}${album?.contentids?.[0]?.filepath}`
    }


    setActiveAlbumItem(album)
    setPublicUrl(publicShareUrl)
    setActiveUrl()
    setPrivateUrl(privateShareUrl)
    setAlbumSharedImage(imagePath)
    setAlbumSharedTitle(album?.albumName)
    shortUrl(publicShareUrl);
    setActiveUrl('public')
  };

  const handleTVCodeChange = (value) => {
    const newValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setTvCode(newValue)
  }

  // console.log("publicUrl ", publicUrl)
  // console.log("privateUrl ", privateUrl)

  return (
    <div>
      <div id="page-top">
        <Navbar />
        <div className="inner-body" id="inner-body">
          <div className="inner-wrapper" id="inner-wrapper">
            <Sidebar />
            <div className="right-section" id="right-section">
              {activeLoad && <Loader id="loader" />}
              {showSessionPopupup && <SessionPopup id="session-popup" />}
              <div className="page-title" id="page-title">
                <div className="d-flex align-items-center" id="page-title-content">
                  <h1 className="sm-heading lite-text">Albums</h1>
                  <button className="btn gradietn_btn rounded-pill px-3 py-2 mb-lg-0 ml-15" onClick={handleCreate} id="create-btn">
                    <span className="d-flex align-items-center">
                      <span>Create</span>
                    </span>
                  </button>
                </div>
              </div>
              {albumList && albumList.length > 0 && (
                <>
                  <div className="content-block" id="content-block">
                    <div className="album-row" id="album-row">
                      {albumList.map((album) => {
                        let publicShareUrl = window.location.protocol + "//" + window.location.host + "/album/" + album?.clientid + "/" + album?.albumid + "/" + album?.public;
                        let privateShareUrl = window.location.protocol + "//" + window.location.host + "/album/" + album?.clientid + "/" + album?.albumid + "/" + album?.private;
                        let title = album.albumName;
                        return (
                          <div key={album.contentid} className="column5 g-3" style={{ cursor: "pointer" }} id={`album-${album.albumid}`}>
                            <div className="album-thumb" id={`album-thumb-${album.albumid}`}>
                              <div className="album-header" id={`album-header-${album.albumid}`}>
                                <div className="dummy-album" onClick={() => { navigate(`/viewalbum/${album.albumid}`); }} id={`album-dummy-${album.albumid}`}></div>
                                {album?.contentids?.[0]?.filetype?.startsWith('video/') ? (
                                  <img
                                    src={album?.thumbnail ? image + album?.thumbnail : `${image}images/app/images/play-default.jpg?auto=compress,format&width=${cardWidths[album?.contentids?.[0]?.contentid] || 0}`}
                                    className="album-img"
                                    alt="thumbnail"
                                    data-id={album?.contentids?.[0]?.contentid}
                                    id={album?.contentids?.[0]?.contentid}
                                  />
                                ) : album?.contentids?.[0] ? (
                                  <img
                                    src={`${image}${album?.thumbnail ? album?.thumbnail : album?.contentids?.[0]?.filepath}?auto=compress,format&width=${cardWidths[album?.contentids?.[0]?.contentid] || 0}`}
                                    alt="Album"
                                    className="album-img"
                                    data-id={album?.contentids?.[0]?.contentid}
                                    id={album?.contentids?.[0]?.contentid}

                                  />
                                ) : (
                                  <img
                                    src={`${image}images/app/images/alumbsDefault.jpg`}
                                    alt="Album"
                                    className="album-img"
                                    data-id="albumEmpty"
                                    id="albumEmpty"
                                  />
                                )}
                              </div>
                              <div className="album-footer" id={`album-footer-${album.albumid}`}>
                                <div className="text-container" id={`album-text-container-${album.albumid}`}>
                                  <h6 id={`album-title-${album.albumid}`}>{album.albumName}</h6>
                                  <span className="tooltip poppins-regular">{album.albumName}</span>
                                  <p className="regular-txt" id={`album-date-${album.albumid}`}>
                                    {moment(album.eventDate).format("MM-DD-YY")}
                                  </p>
                                </div>
                                <div className="album-settings" id={`album-settings-${album.albumid}`}>
                                  <ul>
                                    <li><a><span className="material-symbols-outlined" onClick={() => handleEditPopup(album)} id={`album-edit-${album.albumid}`}>edit</span></a></li>
                                    <li className="middle" onClick={e => { handleSharePopup(album) }}><a><img
                                      src={`${image}images/app/images/share.png`}
                                      className="share-icon"
                                      id={`album-share-${album.albumid}`}
                                    /></a></li>
                                    <li><a><span className="material-symbols-outlined" onClick={() => handleDeletePopup(album)} id={`album-delete-${album.albumid}`}>delete</span></a></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <Modal className="modal fade share_album" show={showShareAlbum} id="share-album-modal">
                      <div className="modal-header gradietn_btn" id="share-album-header">
                        <h5 className="modal-title" id="share-album-title">Share Album - {albumSharedTitle}</h5>
                        <button type="button" className="pop_close" data-bs-dismiss="modal" aria-label="Close" id="close-share-album">
                          <span className="material-symbols-outlined" onClick={() => { setshowShareAlbum(false); setShareUrl(""); setTvCode(""); setTvName(""); setActiveUrl("public") }} id="close-share-album-icon">close</span>
                        </button>
                      </div>
                      <div className="modal-body" id="share-album-body">
                        <div className="d-flex align-items-start justify-content-between" id="share-album-content">
                          <div className="img_block" id="share-album-img-block">
                            <div className='dummy-thumb' id="dummy-thumb"></div>
                            <img src={albumSharedImage} id="album-shared-img" />
                          </div>
                          <div className="share_block" id="share-album-share-block">
                            <div className="d-flex align-items-center justify-content-between" id="share-types">
                              <div className={`d-flex align-items-start share_type ${activeUrl === 'public' ? 'select' : ''}`} id="share-public">
                                <input type="radio" name="shareUrl" checked={activeUrl === 'public'} onClick={() => { shortUrl(publicUrl, 'public'); setTvCode(""); setTvName(""); }} id="share-public-radio" />
                                <div className="text" id="share-public-text">
                                  <h6>Public</h6>
                                  <p>Anyone with link can view the content</p>
                                </div>
                              </div>
                              <div className={`d-flex align-items-start share_type ${activeUrl === 'private' ? 'select' : ''}`} id="share-private">
                                <input type="radio" name="shareUrl" checked={activeUrl === 'private'} onClick={() => { privateSetUrl(privateUrl, 'private'); setTvCode(""); setTvName(""); }} id="share-private-radio" />
                                <div className="text" id="share-private-text">
                                  <h6>Private</h6>
                                  <p>Safe, Secure, and Reliable Sharing</p>
                                </div>
                              </div>
                              <div className={`d-flex align-items-start share_type ${activeUrl === 'tv' ? 'select' : ''}`} id="share-tv">
                                <input type="radio" name="shareUrl" checked={activeUrl === 'tv'} onClick={() => shortUrl(privateUrl, 'tv')} id="share-tv-radio" />
                                <div className="text" id="share-tv-text">
                                  <h6>Smart TV</h6>
                                  <p>Share the code with TV</p>
                                </div>
                              </div>
                            </div>
                            {showTvInput ? (<>
                              <div className="share_section smart_tv">
                                <div className="form-group">
                                  <label>Share With</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={tvName}
                                    placeholder="Share With"
                                    id="share_with_input"
                                    onChange={e => setTvName(e.target.value)}
                                  />
                                </div>
                                <div className="form-group">
                                  <label>Enter Unique Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={tvCode}
                                    placeholder="Enter Code Here"
                                    id="enter_code"
                                    onChange={e => handleTVCodeChange(e.target.value)}
                                  />
                                </div>
                                <button className="gradietn_btn submit" onClick={handleTvSubmit}>
                                  submit
                                </button>
                              </div>
                              {/* <div className="tv_code_section">

                              <label>Enter TV Code</label>
                              <input
                                type="text"
                                className="form-control"
                                value={tvCode}
                                onChange={e => setTvCode(e.target.value)}
                              />
                              <button className="gradietn_btn" onClick={handleTvSubmit}>
                                Submit
                              </button>

                            </div> */}
                            </>
                            ) : (
                              <div className="share_section">

                                {activeUrl == 'private' ?
                                  <>
                                    <div className={privateName == '' ? "share_icons disabled" : "share_icons"}>
                                      {shareUrl !== "" && (
                                        <>
                                          <WhatsappShareButton url={shareUrl} title={albumSharedTitle} separator=":: ">
                                            <button className="icons" id="private_whatsapp"><img src={`${image}images/app/images/whatapp.png`} /></button>
                                          </WhatsappShareButton>
                                          <EmailShareButton url={shareUrl} subject={albumSharedTitle} body="body">
                                            <button className="icons" id="private_mail"><img src={`${image}images/app/images/mail.png`} /></button>
                                          </EmailShareButton>
                                        </>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label>Share With</label>
                                      <input
                                        type="text"
                                        id="share_with"
                                        className="form-control"
                                        value={privateName}
                                        placeholder="Share With"
                                        onChange={(e) => setPrivateName(e.target.value)}
                                        onBlur={(e) => setPrivateUrlName(e.target.value)}
                                      />
                                    </div>
                                    <div className={privateName == '' && shareWith ? "form-group disabled" : "form-group"}>
                                      <label>Shareable Link</label>
                                      <div className="position-relative">
                                        <input type="text" id="share_link" className="form-control" value={privateName && shareWith ? shareUrl : ""} readOnly />
                                        <button className={privateName && shareWith && shareUrl ? "gradietn_btn " : "gradietn_btn disabled"} onClick={(e) => { privateName && shareWith && shareUrl && handleCopy(e) }} id="handlecopy">
                                          <span className="material-symbols-outlined">file_copy</span> Copy
                                        </button>
                                        {copied && <div className="tooltip-text">Copied!</div>}
                                      </div>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className="share_icons">
                                      {shareUrl !== "" && (
                                        <>
                                          <WhatsappShareButton url={shareUrl} title={albumSharedTitle} separator=":: ">
                                            <button className="icons" id="whatsapp"><img src={`${image}images/app/images/whatapp.png`} /></button>
                                          </WhatsappShareButton>
                                          <EmailShareButton url={shareUrl} subject={albumSharedTitle} body="body">
                                            <button className="icons" id="mail"><img src={`${image}images/app/images/mail.png`} /></button>
                                          </EmailShareButton>
                                        </>
                                      )}
                                    </div>

                                    <div className="form-group">
                                      <label>Shareable Link</label>
                                      <div className="position-relative">
                                        <input type="text" className="form-control" value={shareUrl} readOnly />
                                        <button className="gradietn_btn" onClick={handleCopy} id="copy_handle">
                                          <span className="material-symbols-outlined" >file_copy</span> Copy
                                        </button>
                                        {copied && <div className="tooltip-text">Copied!</div>}
                                      </div>
                                    </div></>}




                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Modal>


                    <Modal className="modal fade large_popup delete_popup" show={showDocAlert}>
                      <div className="modal-body">
                        <button className="close-btn"><span className="material-icons" onClick={e => setShowDocAlert(false)}>close</span></button>
                        <span className="material-icons delete-icon">delete_outline</span>
                        <h3>Delete</h3>
                        <p>This action cannot be undone.</p>
                        <p>Are you sure you want to delete Album?</p>
                        <div className="popup-footer">
                          <button className="fill_btn" onClick={(e) => handleDeleteAlbum(deletedAlbum)}> Yes, Delete</button>
                        </div>
                      </div>
                    </Modal>
                    <Modal className="modal fade share_album rename_album" show={showRenamePopup}>
                      <div className="modal-header gradietn_btn">
                        <h5 className="modal-title">Manage Album</h5>
                        <button type="button" className="pop_close" onClick={e => setShowRenamePopup(false)}>
                          <span className="material-symbols-outlined">close</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <div className="img_block"><div className='dummy-thumb'></div>
                            <img src={albumSharedImage} />
                            {/* <img src={`${image}images/app/images/no-files.png`} alt="comingsoon" /> */}
                          </div>
                          <div className="share_block">
                            <div className="share_section mt-0">
                              <div className="form-group">
                                <label>Event Name</label>
                                <div className="position-relative">
                                  <input type="text" className="form-control" onChange={(e) => setAlbumName(e.target.value)} value={albumName} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Event Date</label>
                                <div className="position-relative">
                                  <input type="date" className="form-control" onChange={(e) => setAlbumDate(e.target.value)} value={albumDate} style={{cursor:'pointer'}} />
                                </div>
                              </div>
                              <button className="btn gradietn_btn" onClick={(e) => handleEditAlbum(editAlbum)}>Update</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </>
              )}
              {!activeLoad && albumList.length <= 0 && (
                <div className="content-block blankpage allfiles-blankpage" id="blank-page">
                  <div className="text-center transform-center blank-image-fit" id="blank-image">
                    <img src={`${image}images/app/images/default-img.png`} alt="comingsoon" id="no-album-img" />
                    <h3 className="blankpage-title" id="no-albums-title">
                      NO ALBUMS FOUND
                    </h3>
                  </div>
                </div>
              )}
              {activeLoad && (
                <div className="content-block blankpage allfiles-blankpage" id="loading-block">
                  <Loader />
                </div>
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Albums;