import React, { useState, useRef, useEffect } from "react";
// import { Draggable } from "react-drag-reorder";
import { Draggable } from "react-drag-reorder";


const initialItems = [
    { id: "1", content: "Item 1", order: 1 },
    { id: "2", content: "Item 2", order: 2 },
    { id: "3", content: "Item 3", order: 3 },
    { id: "4", content: "Item 4", order: 4 }
];

const Drag = () => {
    //const [words, setwords] = useState(["Hello", "Hi", "How are you", "Cool"]);
    const [words, setwords] = useState([
        { order: "1", content: "Item 1" },
        { order: "2", content: "Item 2" },
        { order: "3", content: "Item 3" },
        { order: "4", content: "Item 4" }
    ]);

    const [items, setItems] = useState(initialItems);

    // const handleReorder = (currentPosition, newPosition) => {
    //     const updatedItems = Array.from(items);

    //     // Move the item to the new position
    //     const [movedItem] = updatedItems.splice(currentPosition, 1);
    //     updatedItems.splice(newPosition, 0, movedItem);

    //     setItems(updatedItems);
    // };

    const getChangedPos = (currentPos, newPos) => {
        console.log(currentPos, newPos);
    };

    const handleReorder = (currentPosition, newPosition) => {
        const updatedItems = Array.from(items);

        // Move the item to the new position
        const [movedItem] = updatedItems.splice(currentPosition, 1);
        updatedItems.splice(newPosition, 0, movedItem);

        // Update the order key for each item based on its new index
        const reorderedItems = updatedItems.map((item, index) => ({
            ...item,
            order: index + 1
        }));

        setItems(reorderedItems);
    };

    console.log(items, 'items')
    return (
        <div className="flex-container" id="flex-container">
  <div className="row" id="row">
    <Draggable
      onPosChange={handleReorder}
      className="drag-container"
      itemClass="drag-item"
      id="draggable-container"
    >
      {items.map((item) => (
        <div key={item.id} className="item" id={`item-${item.id}`}>
          {item.content}
        </div>
      ))}
    </Draggable>

    {/* <Draggable onPosChange={getChangedPos} id="draggable-words">
      {words && words.length > 0 && words.map((word, idx) => {
        return (
          <div key={idx} className="flex-item" id={`word-${idx}`}>
            {word.content}
          </div>
        );
      })}
    </Draggable> */}
  </div>
</div>

    );

}

export default Drag;