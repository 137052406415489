import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import InsideHeader from './InsideHeader';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let { appname, lambda } = window.app;

const SignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
    const [showOtpBox, setShowOtpBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const navigate = useNavigate();

    const [image, setImg] = useState('');

    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);
            } else {
                setTimeout(checkSiteObject, 50); // Check again after a short delay
            }
        };

        checkSiteObject();
    }, []);

    const validate = () => {
        let validationErrors = {};
        if (!name) {
            validationErrors.name = 'Name is required';
        } else if (name.length < 2) {
            validationErrors.name = 'Name must be at least 2 characters';
        }
        if (!email) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            validationErrors.email = 'Email address is invalid';
        }
        if (phone && !/^\d{10}$/.test(phone)) {
            validationErrors.phone = 'Phone number is invalid';
        }
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            const payload = { name, emailid: email, phone };
            setIsLoading(true);
            try {
                const response = await axios.post(`${lambda}/signUp?appname=${appname}`, payload);
                if (response.data.result === 'Success') {
                    setShowOtpBox(true);
                    // Swal.fire({
                    //     title: 'Success!',
                    //     text: 'OTP sent to your email. Please enter it below.',
                    //     icon: 'success',
                    //     confirmButtonText: 'OK'
                    toast.success("OTP sent to your email. Please enter it below.", {
                        position: "bottom-center",
                        title: 'Success!'
                    });
                } else if (response.data.result === 'User already exists') {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'User already exists.',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    toast.error("User already exists.", {
                        position: "bottom-center",
                        title: 'Error!',

                    });
                }
            } catch (error) {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Something went wrong. Please try again later.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                toast.error("Something went wrong. Please try again later.", {
                    position: "bottom-center",
                    title: 'Error!',
                });
            } finally {
                setIsLoading(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'phone') {
            setPhone(value);
        } else if (name === 'otp') {
            setOtp(value);
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const verifyOTP = async () => {
        const payload = { otp: parseInt(otp), emailid: email };
        setIsOtpLoading(true);
        try {
            const response = await axios.post(`${lambda}/otpVerify?appname=${appname}`, payload);
            if (response?.data?.result?.token) {
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'OTP verified successfully.',
                //     icon: 'success',
                //     confirmButtonText: 'OK'
                toast.success("OTP verified successfully.", {
                    title: 'Success!',
                })
                // navigate('/signin');

                localStorage.setItem("token", response.data.result.token);
                localStorage.setItem("clientid", response.data.result.clientid);



                // localStorage.setItem("clientid", response.result.clientid);
                let currentDate = new Date().toJSON();
                localStorage.setItem("currentSessionClientTime", currentDate);
                // if (location.state) {
                navigate('/dashboard');

            } else {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Invalid OTP. Please try again.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                // });
                document.getElementById('otp').value = ''
                setOtp('')
                toast.error("Invalid OTP. Please try again.", {
                    title: 'Error!',

                });

            }
        } catch (error) {
            // Swal.fire({
            // title: 'Error!',
            // text: 'Something went wrong. Please try again later.',
            // icon: 'error',
            // confirmButtonText: 'OK'
            toast.error("Something went wrong. Please try again later.", {
                position: "bottom-center",
                title: 'Error!'
            });
        } finally {
            setIsOtpLoading(false);
        }
    };

    const resendOTP = async () => {
        const payload = { emailid: email };
        setIsResendLoading(true);
        try {
            const response = await axios.post(`${lambda}/resendMail?appname=${appname}`, payload);
            if (response.data.result === 'Success') {
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'OTP resent to your email.',
                //     icon: 'success',
                //     confirmButtonText: 'OK'
                toast.success("OTP resent to your email.", {
                    position: "bottom-center",
                    title: 'Success!'
                });
                document.getElementById('otp').value = ''
                setOtp('')
            } else {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Invalid OTP. Please try again.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                // });
                toast.error("Please Enter Password", {
                    title: 'Error!',
                });

            }
        } catch (error) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'Something went wrong. Please try again later.',
            //     icon: 'error',
            //     confirmButtonText: 'OK'
            // });
            toast.error("Something went wrong. Please try again later", {
                title: 'Error!',
            });

        } finally {
            setIsResendLoading(false);
        }
    };

    return (
        <div id="signInBlock" className="sign-in-block">
    <InsideHeader />
    <ToastContainer
        id="toastContainer"
        position="bottom-right"
    />
    <div id="signinWrapper" className="signin-wrapper">
        <div id="signinBody" className="signin-body">
            <div id="signinGraphic" className="signin-graphic">
                <img id="signinGraphicImage" src={`${image}images/app/images/signin-graphic_123.png`} alt="Sign In Graphic" />
            </div>
            <div id="signinSection" className="signin-section emaillogin">
                <h1 id="createAccountHeading" className="lg-heading dark-text">Create Account</h1>
                <p id="createAccountText" className="lg-txt lite-text">Let's get started.</p>
                
                {!showOtpBox ? (
                    <form id="createAccountForm" onSubmit={handleSubmit}>
                        <div id="nameInputGroup" className="form-group mt-3">
                            <label htmlFor="nameInput">Name</label>
                            <input
                                id="nameInput"
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                className="form-control"
                                value={name}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                            {errors.name && <div id="nameError" className="text-danger">{errors.name}</div>}
                        </div>

                        <div id="emailInputGroup" className="form-group mt-3">
                            <label htmlFor="emailInput">Email</label>
                            <input
                                id="emailInput"
                                type="email"
                                name="email"
                                placeholder="Enter Email"
                                className="form-control"
                                value={email}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                            {errors.email && <div id="emailError" className="text-danger">{errors.email}</div>}
                        </div>

                        <div id="phoneInputGroup" className="form-group mt-3">
                            <label htmlFor="phoneInput">Phone</label>
                            <input
                                id="phoneInput"
                                type="text"
                                name="phone"
                                placeholder="Enter Phone Number"
                                className="form-control"
                                value={phone}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                            {errors.phone && <div id="phoneError" className="text-danger">{errors.phone}</div>}
                        </div>

                        <div id="signinFooter" className="signin-footer">
                            <button id="createAccountButton" type="submit" disabled={isLoading} className="btn gradietn_btn rounded-pill mr-10">
                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Create
                            </button>
                        </div>
                    </form>
                ) : (
                    <div id="otpBox" className="otp-box">
                        <h2 id="otpHeading">Enter OTP</h2>
                        <input
                            id="otpInput"
                            type="text"
                            name="otp"
                            placeholder="Enter OTP"
                            className="form-control"
                            value={otp}
                            onChange={handleChange}
                        />
                        <div id="otpButtons" className="d-flex align-items-center justify-content-between">
                            <button
                                id="verifyOtpButton"
                                onClick={verifyOTP}
                                disabled={isOtpLoading}
                                className="btn gradietn_btn rounded-pill mr-10 mb-2 mt-2"
                            >
                                {(isOtpLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)} Verify OTP
                            </button>
                            <button
                                id="resendOtpButton"
                                onClick={resendOTP}
                                disabled={isResendLoading}
                                className="btn_outline px-3 py-2 mb-lg-0 me-2 mb-2 mt-2"
                            >
                                {isResendLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Resend OTP
                            </button>
                        </div>
                    </div>
                )}

                <p id="signupPrompt" className="signup-prompt mt-3">
                    Already have an account? <span id="signinLink" className="mx-2" onClick={() => navigate('/signin')}>Sign In</span>
                </p>
            </div>
        </div>
    </div>
    <Footer id="footer" />
</div>

    );
};

export default SignUp;
