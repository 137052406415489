import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import InsideHeader from './InsideHeader';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let { appname, lambda } = window.app;

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
    const [showOtpBox, setShowOtpBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // const { files, totalSize } = location.state || { files: [], totalSize: 0 };
    const [files, setFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [image, setImg] = useState('');

    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);
            } else {
                setTimeout(checkSiteObject, 50); // Check again after a short delay
            }
        };

        checkSiteObject();
    }, []);
    useEffect(() => {
        if (location.state) {
            setVideoFiles(location.state.videoFiles);
            setTotalSize(location.state.totalSize);
            setFiles(location.state.files);

        }

    }, [location.state]);
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/signin");
        } else {
            navigate("/dashboard");
        }
    }, []);
    console.log('files', files)
    console.log('totalSize', totalSize)

    const validate = (value = "") => {
        let validationErrors = {};
        if (!email) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            validationErrors.email = 'Email address is invalid';
        }
        if (value === "otp") {
            return validationErrors;
        } else {
            if (!password && !showOtpBox) {
                validationErrors.password = 'Password is required';
            } else if (password.length < 8 && !showOtpBox) {
                validationErrors.password = 'Password must be at least 8 characters';
            }
        }

        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            const payload = { emailid: email, logintype: 'password', password };
            setIsLoading(true);
            try {
                const response = await axios.post(`${lambda}/signin?appname=${appname}`, payload);
                const result = response.data.result;
                if (response.data.result.token || result === 'Success') {
                    // Swal.fire({
                    //     title: 'Success!',
                    //     text: 'Signed in successfully.',
                    //     icon: 'success',
                    //     confirmButtonText: 'OK'
                    // }).then(() => {
                    localStorage.setItem("token", response.data.result.token);
                    localStorage.setItem("clientid", result.clientid);



                    // localStorage.setItem("clientid", response.result.clientid);
                    let currentDate = new Date().toJSON();
                    localStorage.setItem("currentSessionClientTime", currentDate);

                    let dCode = localStorage.getItem("c");
                    let dName = localStorage.getItem("n");

                    if (dCode && dName) {
                        navigate('/qrcode/' + dCode + '/' + dName);
                    } else {
                        navigate('/dashboard', { state: { files, totalSize } });
                    }


                    // } else {
                    //     navigate('/dashboard');
                    // }
                    // });
                } else if (result === 'User not found') {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'User not found. Please sign up.',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    toast.error("User not found. Please sign up.", {
                        position: "bottom-center"
                    });
                } else if (result === 'Invalid password') {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'Invalid password. Please try again.',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    // });
                    setError("Invalid password. Please try again");
                    setTimeout(function () { setError("") }, 3000);
                } else if (result === 'Please log in using OTP and set a password') {
                    Swal.fire({
                        title: 'Attention!',
                        text: 'Please log in using OTP and set a password.',
                        icon: 'info',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        setShowOtpBox(true);
                    });
                } else {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'Invalid email or password. Please try again.',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    toast.error("nvalid email or password. Please try again.", {
                        title: 'Error!'
                    });
                }
            } catch (error) {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Something went wrong. Please try again later.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                toast.error("Something went wrong. Please try again later.", {
                    title: 'Error!'
                });
            } finally {
                setIsLoading(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };


    const handleLoginWithOtp = async () => {
        const validationErrors = validate('otp');
        if (Object.keys(validationErrors).length === 0) {

            setIsOtpLoading(true);
            try {
                const payload = { emailid: email, logintype: 'otp' };
                const response = await axios.post(`${lambda}/signin?appname=${appname}`, payload);
                if (response.data.result === 'Email Sent Successfully') {
                    // Swal.fire({
                    // title: 'Success!',
                    // text: 'OTP sent to your email. Please enter it below.',
                    // icon: 'success',
                    // confirmButtonText: 'OK'
                    toast.success("OTP sent to your email. Please enter it below.", {

                    });
                    setShowOtpBox(true);
                } else if (response.data.result === 'User not found') {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'User not found. Please sign up.',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    toast.error("User not found. Please sign up.", {
                        title: 'Error!'
                    });
                }
                else {
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: 'Failed to send OTP. Please try again.',
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    toast.error("Failed to send OTP. Please try again.", {
                        title: 'Error!'
                    });
                }
            } catch (error) {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Something went wrong. Please try again later.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'            
                toast.error("Something went wrong. Please try again later.", {
                    title: 'Error!'
                });
            } finally {
                setIsOtpLoading(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };


    const verifyOTP = async () => {
        if (!/^\d{5}$/.test(otp)) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'Invalid OTP format. Please enter a 5-digit number.',
            //     icon: 'error',
            //     confirmButtonText: 'OK'
            // });
            toast.error("Invalid OTP format. Please enter a 5-digit number.", {
                title: 'Error!'
            });
            document.getElementById('otp').value = ''
            setOtp('')
            return; // Do not proceed with API call if OTP format is invalid
        }
        const payload = { otp: parseInt(otp), emailid: email, logintype: 'otp' };
        setIsOtpLoading(true);
        try {
            const response = await axios.post(`${lambda}/signin?appname=${appname}`, payload);
            if (response.data.result.token || response.result === "Success") {
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'Signed in successfully.',
                //     icon: 'success',
                //     confirmButtonText: 'OK'
                // }).then(() => {
                localStorage.setItem("token", response.data.result.token);
                // localStorage.setItem("clientid", response.result.clientid);
                let currentDate = new Date().toJSON();
                localStorage.setItem("currentSessionClientTime", currentDate);
                localStorage.setItem("clientid", response.data.result.clientid);

                let dCode = localStorage.getItem("c");
                let dName = localStorage.getItem("n");

                if (dCode && dName) {
                    navigate('/qrcode/' + dCode + '/' + dName);
                } else {
                    navigate('/dashboard', { state: { files, totalSize } });
                }
                // } else {
                //     navigate('/dashboard');
                // }

                // });
            } else {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Invalid OTP. Please try again.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                toast.error("Invalid OTP. Please try again.", {
                    title: 'Error!'
                });
                document.getElementById('otp').value = ''
                setOtp('')
            }
        } catch (error) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'Something went wrong. Please try again later.',
            //     icon: 'error',
            //     confirmButtonText: 'OK'
            toast.error("Something went wrong. Please try again later.", {
                title: 'Error!'

            });
        } finally {
            setIsOtpLoading(false);
        }
    };

    const resendOTP = async () => {
        const payload = { emailid: email, type: "signin" };
        setIsResendLoading(true);
        try {
            const response = await axios.post(`${lambda}/resendMail?appname=${appname}`, payload);
            if (response.data.result === 'Success') {
                // Swal.fire({
                //     title: 'Success!',
                //     text: 'OTP resent to your email.',
                //     icon: 'success',
                //     confirmButtonText: 'OK'
                toast.success("OTP resent to your email.", {
                    title: 'Success!',

                });
            } else if (response.data.result === 'User not found') {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'User not found. Please sign up.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                toast.error("IUser not found. Please sign up.", {
                    title: 'Error!',

                });
            } else if (response.data.result === "Invalid otp entered") {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Invalid OTP. Please try again.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                document.getElementById('otp').value = ''
                setOtp('')
                toast.error("Invalid OTP. Please try again.", {
                    title: 'Error!',
                });
            } else {
                // Swal.fire({
                //     title: 'Error!',
                //     text: 'Failed to resend OTP. Please try again.',
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                toast.error("Failed to resend OTP. Please try again.", {
                    title: 'Error!',
                });
            }
        } catch (error) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'Something went wrong. Please try again later.',
            //     icon: 'error',
            //     confirmButtonText: 'OK'
            // });
            toast.error("Something went wrong. Please try again later.", {
                title: 'Error!',
            });
        } finally {
            setIsResendLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'otp') {
            setOtp(value);
        }

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleFocus = (e) => {
        const { name } = e.target;
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const navigateToSignUp = () => {
        navigate('/signup');
    };

    return (
        <div id="signInBlock" className="sign-in-block">
        <InsideHeader />
        <ToastContainer
            id="toastContainer"
            position="bottom-right"
        />
        <div id="signinWrapper" className="signin-wrapper">
            <div id="signinBody" className="signin-body">
                <div id="signinGraphic" className="signin-graphic">
                    <img id="signinGraphicImage" src={`${image}images/app/images/signin-graphic_123.png`} alt="Sign In Graphic" />
                </div>
                <div id="signinSection" className="signin-section emaillogin">
                    <h1 id="signinHeading" className="lg-heading dark-text">Sign In</h1>
                    <p id="signinText" className="lg-txt lite-text">Join Share Clix to get started.</p>
    
                    {!showOtpBox && (
                        <>
                            <div id="emailInputGroup" className="form-group mt-3">
                                <label id="emailLabel">Email</label>
                                <input
                                    id="emailInput"
                                    type="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    className="form-control"
                                    value={email}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                />
                                {errors.email && <div id="emailError" className="text-danger">{errors.email}</div>}
                            </div>
    
                            <div id="passwordInputGroup" className="form-group">
                                <label id="passwordLabel">Password</label>
                                <input
                                    id="passwordInput"
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    className="form-control"
                                    value={password}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                />
                                {errors.password && <div id="passwordError" className="text-danger">{errors.password}</div>}
                            </div>
                            <p id="generalError" className="text-danger">{error}</p>
                        </>
                    )}
                    <div id="signinFooter" className="signin-footer">
                        {!showOtpBox && (
                            <button
                                id="signInButton"
                                type="button"
                                onClick={handleSubmit}
                                className="btn gradietn_btn rounded-pill mr-10"
                            >
                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Sign In
                            </button>
                        )}
                        {!showOtpBox && (
                            <button
                                id="loginWithOtpButton"
                                type="button"
                                className="btn_outline px-3 py-2 mb-lg-0 me-2"
                                onClick={handleLoginWithOtp}
                            >
                                {isOtpLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Login with OTP
                            </button>
                        )}
                    </div>
    
                    {showOtpBox && (
                        <div id="otpBox" className="otp-box mt-3">
                            <h2 id="otpHeading">Enter OTP</h2>
                            <p id="otpEmailText">Email: {email}</p>
                            <div id="otpInputGroup" className='form-group mt-3'>
                                <input
                                    id="otpInput"
                                    type="text"
                                    name="otp"
                                    placeholder="Enter OTP"
                                    className="form-control"
                                    value={otp}
                                    onChange={handleChange}
                                />
                                <p id="otpError" className="text-danger">{error}</p>
                            </div>
                            <div id="otpFooter" className='signin-footer'>
                                <button id="verifyOtpButton" onClick={verifyOTP} className="btn gradietn_btn rounded-pill mr-10">
                                    {isOtpLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Verify OTP
                                </button>
                                <button id="resendOtpButton" onClick={resendOTP} className="btn_outline px-3 py-2 mb-lg-0 me-2">
                                    {isResendLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : ''} Resend OTP
                                </button>
                            </div>
                        </div>
                    )}
                    <p id="signupPrompt" className="signup-prompt mt-3">
                        Don't have an account? 
                        <span id="signUpLink" onClick={navigateToSignUp} className="mx-2 text-primary" style={{ cursor: 'pointer' }}>Sign Up</span>
                    </p>
                </div>
            </div>
        </div>
        <Footer id="footer" />
    </div>
    
    );
};

export default SignIn;
