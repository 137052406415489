
import React, { useState, useEffect, useContext } from "react";
import Navbar from './Navbar';
import Footer from './Footer';

import InsideHeader from './InsideHeader';
import SessionPopup from "./SessionPopup";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

let { appname, lambda } = window.app;
const ContactUs = () => {
  const navigate = useNavigate();
  // const [menus, setMenus] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nameerror, setNameError] = useState('');
  const [subject, setSubject] = useState('');
  const [msg, setMsg] = useState('');
  const [errmsg, setErrmsg] = useState('');
  const [suberror, setSubError] = useState('');
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const [showSessionPopupup, setShowSessionPopupup] = useState(false);
  const [activeCheckId, setActiveCheckId] = useState("");
  const [img, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site


    checkSiteObject();
    fetchUserData()
  }, []);

  const checkSiteObject = () => {
    if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
      setImg(window.site.common.imageCloudfront);


    } else {
      setTimeout(checkSiteObject, 50); // Check again after a short delay
    }
  };

  const fetchUserData = async () => {

    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);
      if (response.data?.result === "Client not found") {
        localStorage.removeItem("token");
        localStorage.removeItem("currentSessionClientTime");
        localStorage.removeItem("clientid");
        // navigate('/signin');
      } else if (response.status === 200 && response.data?.result?.[0]) {
        setUser(response.data.result[0]);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };



  const validateEmail = (e) => {

    setEmail(e.target.value);

  }

  function formvalidation() {

    let flag = true;
    if (localStorage.getItem("clientid")) {
      if (msg === "") {
        setErrmsg("Please enter Message");
        setTimeout(function () { setErrmsg("") }, 3000);
        flag = false;

        return;
      }
    } else {
      const regEx = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}(.[a-zA-Z{2,8}])?/g;
      if (regEx.test(email)) {
        setEmailError("");
        setTimeout(function () { setEmailError("") }, 2000)
      } else if (!regEx.test(email) && email !== "") {
        setEmailError("Email is Not Valid");
        flag = false;

      }

      if (name == "") {
        setNameError("Please Enter Name");
        flag = false;

        return;
      }

      if (email == "") {
        setEmailError("Please Enter Email");
        flag = false;

        return;
      }
      if (subject == "") {
        setSubError("Please Enter Subject");
        flag = false;

        return;
      }
      if (msg === "") {
        setErrmsg("Please Enter Message");
        flag = false;

        return;
      }
    }
    // console.log('subject',subject)
    if (flag) {
      
        handleContactus();
      
    } else {
      setActiveCheckId("")
    }

    return;
  }
  const AddNotify = () => toast.success('Your message has been sent. Thank you!', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  },)
  const ShowError = () => toast.error('Sorry something Went Wrong, Please try again.', {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  },)
  const handleContactus = async () => {
    try {
      setActiveCheckId("isload")
      if (user && user.name) {
        

        const response = await axios.post(
          `${lambda}/contactus?appname=${appname}`,
          {
            "name": user && user.name ? user.name : name,
            "emailid": user && user.emailid ? user.emailid : email,
            "subject": subject,
            "message": msg
          }
        );
        // console.log(response);

        if (response?.data?.statusCode == 200) {
          if(response?.data?.result == "Invalid token or Expired"){
            setShowSessionPopupup(true)
          }else{
            // console.log("success")
          // AddNotify()
          toast.success(`Your message has been sent. Thank you!`);
          setActiveCheckId("")
          // setSuccess("Your message has been sent. Thank you!");
          setName("");
          setEmail("");
          setSubject("");
          setMsg("");
          setTimeout(function () {
            setSuccess("");
          }, 3000);
        }
        }
      } else {
        const response = await axios.post(
          `${lambda}/contactus?appname=${appname}`,
          {
            "name":  name,
            "emailid":email,
            "subject": subject,
            "message": msg
          }
        );

       
        // console.log(response);
        if (response?.data?.statusCode == 200) {
          setActiveCheckId("")
          toast.success(`Your message has been sent. Thank you!`);
          // setSuccess("Your message has been sent. Thank you!");
          setName("");
          setEmail("");
          setSubject("");
          setMsg("");
          setTimeout(function () {
            setSuccess("");
          }, 3000);
        }
      }

    } catch {
      ShowError()

      setActiveCheckId("")
      console.log("error");
    }
  };


  const handleSubmit = (e) => {
    formvalidation();
  }
  const handleName = (e) => {
    setNameError("");
  }

  const handleEmail = (e) => {
    setEmailError("");
  }

  const handleSubject = (e) => {
    setSubError("");
  }

  const handleMessage = (e) => {
    setErrmsg("");
  }
  // console.log('data', clientData);
  return (

    <div id="page-top">

    {user && user.name ? <Navbar id="navbar" /> : <InsideHeader flag={true} id="inside-header" />}
  
    <div className="inner-body contact_us" id="contact-us-body">
      <div className="section inner-container" id="contact-us-container">
        <h1 className="sec-heading mt-5" id="contact-us-heading">CONTACT <span>US</span></h1>
        <p className="heading-tag mt-2 text-center mb-4 max600" id="contact-us-description">If you would like to learn more about the platform or have other enquiries, here are the different ways we can be contacted.</p>
        <section id="contact" className="contact section-bg pt-0 pb-0" id="contact-section">
          <div className="container" data-aos="fade-up" id="contact-container">
            <div className="row" id="contact-row">
              <div className="col-lg-6" id="address-col">
                <div className="info-box mb-4" id="address-info-box">
                  <i className="material-symbols-outlined" id="address-icon"> place </i>
                  <h3 id="address-heading">Our Address</h3>
                  <p id="address-text">Address: Flat No 101, Sri RK Homes, Marripalem VUDA Layout, Visakhapatnam, India - 530016.</p>
                </div>
              </div>
  
              <div className="col-lg-6 col-md-6" id="email-col">
                <div className="info-box mb-4" id="email-info-box">
                  <i className="material-symbols-outlined" id="email-icon"> email </i>
                  <h3 id="email-heading">Email Us</h3>
                  <p id="email-text"><a href="mailto:contact@shareclix.com" id="email-link">contact@shareclix.com</a></p>
                </div>
              </div>
            </div>
  
            <div className="row mt-3" id="map-row">
  
              <div className="col-lg-6" id="map-col">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.010235353122!2d83.2438927746347!3d17.744156892430485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3967fe69ecb315%3A0xb6fa2fc10ca051c7!2sSanchaninfo%20Solutions%20Private%20limited!5e0!3m2!1sen!2sin!4v1732612112320!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ border: "0", width: "100%", height: "384px" }}
                  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                  id="google-map"></iframe>
              </div>
  
              <div className="col-lg-6" id="contact-form-col">
                <div className="php-email-form" id="contact-form">
                  <div className="row" id="form-row">
                    {localStorage.getItem("clientid") ?
                      <>
                        <div className="col-md-6" id="name-col">
                          <div className="form-group" id="name-group">
                            <label id="name-label">Name</label>
                            <input type="text" name="name" className="form-control" value={user?.name} required disabled id="name-input" />
                          </div>
                        </div>
  
                        <div className="col-md-6" id="email-id-col">
                          <div className="form-group" id="email-id-group">
                            <label id="email-id-label">Email ID</label>
                            <input type="text" className="form-control" name="emailid" placeholder="Email ID" value={user?.emailid} disabled id="email-id-input" />
                          </div>
                        </div>
                      </> :
                      <>
                        <div className="col-md-6" id="name-col">
                          <div className="form-group" id="name-group">
                            <label id="name-label">Name</label>
                            <input type="text" className="form-control" placeholder="Please enter Name" value={name} onChange={(e) => setName(e.target.value)} autoComplete="on" onFocus={(e) => handleName(e)} required id="name-input" />
                            {nameerror !== "" && <span className="errormsg" id="name-error" style={{ fontWeight: 'bold', color: 'red' }}>{nameerror}</span>}
                          </div>
                        </div>
                        <div className="col-md-6" id="email-col">
                          <div className="form-group" id="email-group">
                            <label id="email-label">Email ID</label>
                            <input type="text" className="form-control" placeholder="Email ID" value={email} onChange={(e) => validateEmail(e)} onFocus={(e) => handleEmail(e)} autoComplete="on" id="email-input" />
                            <span className="errormsg" id="email-error" style={{ fontWeight: 'bold', color: 'red' }}>{emailError}</span>
                          </div>
                        </div>
                      </>
                    }
  
                    <div className="col-md-12" id="subject-col">
                      <div className="form-group" id="subject-group">
                        <label id="subject-label">Subject</label>
                        <input type="text" name="subject" className="form-control" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} onFocus={(e) => handleSubject(e)} id="subject-input" />
                        <span className="errormsg" id="subject-error" style={{ fontWeight: 'bold', color: 'red' }}>{suberror}</span>
                      </div>
                    </div>
  
                    <div className="col-md-12" id="message-col">
                      <div className="form-group" id="message-group">
                        <label id="message-label">Message</label>
                        <textarea className="form-control" name="message" rows="5" placeholder="Message" value={msg} onChange={(e) => setMsg(e.target.value)} onFocus={(e) => handleMessage(e)} required id="message-input"></textarea>
                        {errmsg !== "" && <span className="errormsg" id="message-error" style={{ fontWeight: 'bold', color: 'red' }}>{errmsg}</span>}
                      </div>
                    </div>
  
                    {success !== "" && <span className="errormsg" id="success-message" style={{ fontWeight: 'bold', color: 'green' }}>{success}</span>}
                    <div className="text-center">
                      <button className="gradietn_btn" onClick={e => handleSubmit(e)} id="submit-btn">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
        </section>
      </div>
  
      <ToastContainer id="toast-container" />
      <Footer id="footer" />
    </div>
  </div>
  

  );
};

export default ContactUs;
