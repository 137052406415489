import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Navbar from './Navbar'; // Ensure you have Navbar component
import Sidebar from './Sidebar'; // Ensure you have Sidebar component
import moment from 'moment';
import Modal from "react-bootstrap/Modal";
import Loader from './Loader'
import { ToastContainer, toast } from 'react-toastify';
import Footer from './Footer';
let { appname, lambda } = window.app;
const MyDevices = () => {
  const [transactions, setTransactions] = useState([]);
  const [image, setImg] = useState('');
  const [addDevice, setAddDevice] = useState(false)
  const [deviceName, setDeviceName] = useState(null)
  const [deviceNameValue, setDeviceNameValue] = useState(null)
  const [activeLoad, setActiveLoad] = useState(false);
  const [showDocAlert, setShowDocAlert] = useState(false);
  const [deletedAlbum, setDeleteItem] = useState(null);
  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  useEffect(() => {

    fetchTransactions();
  }, []);
  const fetchTransactions = async () => {
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/devicesharesList?appname=${appname}&clientid=${clientid}`);
      // setVideos(response.data.result);
      setTransactions(response.data.result);
      
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };
  const noRecordsFound = () => (
    <div className="content-block blankpage allfiles-blankpage">
        <div className="text-center transform-center blank-image-fit">
          <img src={`${image}images/app/images/default-img.png`} alt="devices" />

          <h3 className="blankpage-title">NO DEVICES FOUND</h3>
      
      </div>
    </div>
  );
  const columns = [

    { name: 'Type', selector: row => row?.type, sortable: true },
    { name: 'Code', selector: row => row?.code, sortable: true },

    { name: 'Created At', selector: row => formatDate(row?.created), sortable: true },

  ];
  function formatDate(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;

    const strMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${day}-${month}-${year}, ${hours}:${strMinutes} ${ampm}`;
  }



  const handleAddToDevice = async () => {
    
    if (!deviceName?.trim()) {
      toast.error('Please enter device id.');
      return;
    }
    if (!deviceNameValue?.trim()) {
      toast.error('Please enter device value.');
      return;
    }
    try {
      setActiveLoad(true)
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      setAddDevice(false)
      const response = await axios.post(
        `${lambda}/deviceShareLinkUpdate?appname=${appname}&clientid=${clientid}${deviceName ? `&code=${deviceName}` : ''}${deviceNameValue ? `&devicename=${deviceNameValue}` : ''}`
      );
      
      setActiveLoad(false)
      console.log('devices added:', response.data);
      fetchTransactions();
      if(response.data.result == "code is invalid"){
        toast.error(`Code is Invalid`);
      }else if(response.data.result == "code already paired"){
        toast.error(`Device already paired`);
      }else{
        toast.success(`Device added successfully!`);
      }
      

    } catch (error) {
      setActiveLoad(false)
      console.error('Error adding to devices:', error);
      toast.error('Failed to add device.');
    }
  };

  const handleDelete = async (deviceId) => {
    setActiveLoad(true)
    setShowDocAlert(false)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${lambda}/devicesharesList?appname=${appname}&clientid=${clientid}&deviceid=${deviceId}`);
      // setVideos(response.data.result);
      setActiveLoad(false)
      toast.success(`Device deleted successfully!`);
      fetchTransactions();
      setDeleteItem(null)
    } catch (error) {
      setActiveLoad(false)
      toast.error('Failed to delete device.');
      console.error('Error fetching videos:', error);
    }
  };

  return (
    <div id="page-top">
    <Navbar id="navbar-top" />
    <div className="inner-body" id="inner-body">
      <div className="inner-wrapper" id="inner-wrapper">
        <Sidebar id="sidebar" />
        <div className="right-section" id="right-section">
          {activeLoad && <Loader id="loader" />}
          <div className="page-title" id="page-title">
            <h1 className="sm-heading lite-text" id="page-title-text">My Devices</h1>
          </div>

          <div className="content-block blankpage allfiles-blankpage" id="devices-content-block">
            <div className="small-wrapper" id="small-wrapper">
              <div className="Mydevice-header" id="mydevice-header">
                <div className="Mydevice-title" id="mydevice-title">
                  <h2 id="manage-devices-title">Manage Your Devices</h2>
                  <p id="device-description">These have been active on this account. You can delete any unfamiliar devices for added security.</p>
                </div>
                <button
                  className="btn gradietn_btn rounded-pill"
                  onClick={(e) => setAddDevice(true)}
                  id="add-device-button"
                >
                  Add Device<span className="material-symbols-outlined ms-2" id="add-device-icon">add</span>
                </button>
              </div>
              <div className="row" id="device-row">
                {transactions && transactions?.length > 0 ? transactions.map((item, index) => (
                  <div className="col-md-6" id={`device-card-${index}`} key={index}>
                    <div className="device-card" id={`device-card-${index}-content`}>
                      <div className="device-card-header" id={`device-card-header-${index}`}>
                        <div className="device-title" id={`device-title-${index}`}>
                          <img src={`${image}images/app/images/television.png`} className="me-3" alt="TV" id={`device-image-${index}`} />
                          <h3 id={`device-name-${index}`}>{item?.deviceName}</h3>
                        </div>
                        <button
                          className="card-btn"
                          onClick={(e) => { setShowDocAlert(true); setDeleteItem(item.deviceid) }}
                          id={`delete-device-button-${index}`}
                        >
                          <span className="material-symbols-outlined" id={`delete-icon-${index}`}>delete</span>Delete
                        </button>
                      </div>
                      <div className="device-card-footer" id={`device-card-footer-${index}`}>
                        <p id={`device-code-${index}`}>Code: {item?.code} | {item?.type}</p>
                        <p id={`device-date-added-${index}`}>Added on  {moment(item?.created).format("MM-DD-YY")} </p>
                      </div>
                    </div>
                  </div>
                )) : noRecordsFound()}

              </div>
            </div>
          </div>

          <Modal className="access-denied delete_popup" show={addDevice} id="add-device-modal">
            <div className="modal-body" id="add-device-modal-body">
              <button className="close-btn" id="close-add-device-modal" onClick={e => setAddDevice(false)}>
                <span className="material-icons" id="close-modal-icon">close</span>
              </button>
              <span className="material-icons delete-icon" id="add-device-icon">tv</span>
              <h3 id="add-device-modal-title">Add Device</h3>
              <div className="form-group" id="add-device-form-group">
                <input
                  type="text"
                  name="deviceid"
                  onChange={(e) => setDeviceNameValue(e.target.value)}
                  className="form-control"
                  placeholder="Enter Device Name"
                  id="device-name-input"
                />
                <br />
                <input
                  type="text"
                  name="deviceid"
                  onChange={(e) => setDeviceName(e.target.value)}
                  className="form-control"
                  placeholder="Enter Device ID"
                  id="device-id-input"
                />
              </div>
              <div className="popup-footer" id="add-device-popup-footer">
                <button className="fill_btn"  onClick={e => handleAddToDevice(e)} id="add-device-button-modal"> Add </button>
              </div>
            </div>
          </Modal>

          <Modal className="modal fade large_popup delete_popup" show={showDocAlert} id="delete-device-modal">
            <div className="modal-body" id="delete-device-modal-body">
              <button className="close-btn" id="close-delete-device-modal" onClick={e => setShowDocAlert(false)}>
                <span className="material-icons" id="close-modal-icon">close</span>
              </button>
              <span className="material-icons delete-icon" id="delete-icon-modal">delete_outline</span>
              <h3 id="delete-device-modal-title">Delete</h3>
              <p id="delete-device-warning">This action cannot be undone.</p>
              <p id="delete-device-question">Are you sure you want to delete Album?</p>
              <div className="popup-footer" id="delete-device-footer">
                <button className="fill_btn" onClick={(e) => handleDelete(deletedAlbum)} id="confirm-delete-button"> Yes, Delete</button>
              </div>
            </div>
          </Modal>

          <ToastContainer id="toast-container-footer" />
          <Footer id="footer" />
        </div>
      </div>
    </div>
  </div>

  );
};

export default MyDevices;
