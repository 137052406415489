import React from 'react'
import { useNavigate } from 'react-router-dom';
import InsideHeader from './InsideHeader';
import Footer from './Footer';
let { appname, lambda } = window.app;
function ComingSoon(props) {
    const navigate = useNavigate();
    const handleBackToHome = () => {
        navigate('/'); // Navigate to the home page
    };
    //   const image ='asdf';
    return (
        <div id="page-top">

        <InsideHeader flag={true} id="inside-header" />
       
        <div className='right-section' id="right-section">
            <div className="content-block blankpage commingsoon-blankpage" id="coming-soon-block">
                <div className="text-center transform-center blank-image-fit" id="blank-image-fit">
    
                    <img src={`${props?.image}images/app/images/default-img.png`} alt="comingsoon" id="coming-soon-img" />
    
                    <h3 className="blankpage-title" id="coming-soon-title">
                        COMING SOON
                    </h3>
                    <p id="under-construction-text">The Page is Under Construction.</p>
                    {!props?.flag &&
                        <div className="d-flex justify-content-center" id="back-to-home-btn-container">
                            <button className="btn_style" onClick={handleBackToHome} id="back-to-home-btn">Back to Home</button>
                        </div>
                    }
                </div>
            </div>
    
            <Footer id="footer"/>
        </div>
    </div>
    
    );
}

export default ComingSoon