import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ComingSoon from './ComingSoon';

const ManageContent = () => {
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  return (
    <div id="outer-container">
  <div id="page-top">
    <Navbar id="navbar-component" />
    <div className="inner-body" id="inner-body">
      <div className="inner-wrapper" id="inner-wrapper">
        <Sidebar id="sidebar-component" />
        <ComingSoon flag={true} image={image} id="coming-soon-component" />
      </div>
    </div>
    {/* <Footer id="footer-component"/> */}
  </div>
</div>


  )
}
export default ManageContent;