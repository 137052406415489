import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ComingSoon from './ComingSoon';
import axios from 'axios';
import moment from 'moment';
import Loader from './Loader';
import Footer from './Footer';
import DataTable from 'react-data-table-component';
import { useNavigate, useLocation } from 'react-router-dom';
let { appname, lambda } = window.app;

const ViewClients = () => {
    const navigate = useNavigate();
    const [image, setImg] = useState('');
    const [privateList, setPrivateList] = useState([])
    const [transactionList, setTransactionList] = useState([])
    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
            if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
                setImg(window.site.common.imageCloudfront);


            } else {
                setTimeout(checkSiteObject, 50); // Check again after a short delay
            }
        };

        checkSiteObject();
        shareData();
    }, []);


    const shareData = async () => {
        try {
            const clientid = localStorage.getItem('viewClientId');
            const token = localStorage.getItem('token');

            const response = await axios.get(`${lambda}/viewClients?appname=${appname}&clientid=${clientid}`);
            console.log("rrr ", response)
            setPrivateList(response.data.result);
            setTransactionList(response.data.result.transactionHistory)

        } catch (error) {
            console.error('Error fetching album info:', error);
        }
    };

    //   const shareUpdate = async (codeValue, status) => {
    //     try {
    //       const clientid = localStorage.getItem('clientid');
    // const token = localStorage.getItem('token');

    //       const response = await axios.post(`${lambda}/shareLinkUpdate?appname=${appname}&clientid=${clientid}&code=${codeValue}&status=${status}`);
    //       setPrivateList(response.data.result);
    //       shareData()
    //     } catch (error) {
    //       console.error('Error fetching album info:', error);
    //     }
    //   };


    useEffect(() => {
        console.log(privateList)
    }, [privateList]);

    const columns = [
        {
            name: '',
            selector: row => row?.name,
            width: '10%',
        },
        {
            name: 'Transaction Id',
            selector: row => row?.transactionId,
            width: '10%',
        },
        {
            name: 'Pay Amount',
            selector: row => row?.payAmount,
            width: '15%',
        },
        {
            name: 'Currency',
            selector: row => row?.currency,
            width: '5%',
        },
        {
            name: 'Plan Type',
            selector: row => row?.planType,
            width: '8%',
        },
        {
            name: 'Storage',
            selector: row => row?.storage,
            width: '17%',
        },
        {
            name: 'Grand Total',
            selector: row => row?.grandTotal,
            width: '17%',
        },
        // {
        //   name: 'Status',
        //   selector: row => row?.status,
        //   width: '10%',
        // },
        // {
        //   name: 'Created',
        //   selector: row => moment(row?.created).format('MM-DD-YY'),
        //   width: '10%',
        // },
    ];



    const noRecordsFound = () => (
        <div className="content-block blankpage allfiles-blankpage">

            <div className="text-center transform-center blank-image-fit">

                <img src={`${image}images/app/images/default-img.png`} alt="comingsoon" />

                <h3 className="blankpage-title">
                    NO TRANSACTION RECORDS FOUND
                </h3>
            </div>

        </div>
    );

    const myNewTheme = {
        rows: {
            fontSize: '25px'
        }
    }
    const handleClick = () => {
        navigate('/client')
    }
    return (
        <div id="mainContainer">
            <div id="pageTop">
                <Navbar id="navbar" />
                <div id="innerBody" className="inner-body">
                    <div id="innerWrapper" className="inner-wrapper">
                        <Sidebar id="sidebar" />
                        <div id="rightSection" className="right-section">
                            <div id="pageTitle" className="page-title">
                                <h1 id="sharedPageHeading" className="sm-heading lite-text">View Client</h1>
                            </div>
                            <div className="left-section" id="leftSection">
                                <a className="skeleton-btn back-btn mb-2" onClick={handleClick} id="back-button">
                                    <span className="material-symbols-outlined" id="back-icon"> chevron_left</span>
                                    Back
                                </a>
                            </div>
                            <div id="blankPage" className="blankpage">
                                {/* <div id="tableContainer" className="text-center">
                            <table id="dataTable" className="table">
                                <thead>
                                    <tr>
                                        
                                        <th id="columnName" width="25%" scope="col">Name</th>
                                        <th id="columnEmail" width="20%" scope="col">Email Id</th>
                                        <th id="columnNumber" width="20%" scope="col">Phone Number</th>
                                        <th id="columnAlbums" width="20%" scope="col">Total Albums</th>
                                        <th id="columnVideos" width="20%" scope="col">Total Videos</th>
                                        <th id="columnImages" width="20%" scope="col">Total Images</th>
                                        <th id="columnShares" width="20%" scope="col">Total Shares</th>
                                        <th id="columnDevices" width="20%" scope="col">Total Devices</th>
                                        <th id="columnStorage" width="20%" scope="col">Used Storage</th>
                                        <th id="columnStorage" width="20%" scope="col">Remaining Storage</th>
                                        <th id="columnStorage" width="20%" scope="col">Total Storage</th>
                                        <th id="columnStatus" width="20%" scope="col">Status</th>
                                        <th id="columnCreated" width="30%" scope="col">Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                        <tr id={`itemRow`}>
                                           
                                            <td>{privateList?.name ? privateList?.name : "N/A"}</td>
                                            <td>{privateList?.emailid ? privateList?.emailid : "N/A"}</td>
                                            <td>{privateList?.contactno ? privateList?.contactno : "N/A"}</td>
                                            <td>{privateList?.totalAlbums ? privateList?.totalAlbums : "N/A"}</td>
                                            <td>{privateList?.totalVideos ? privateList?.totalVideos : "N/A"}</td>
                                            <td>{privateList?.totalImages ? privateList?.totalImages : "N/A"}</td>
                                            <td>{privateList?.totalShares ? privateList?.totalShares : "N/A"}</td>
                                            <td>{privateList?.totalDevices ? privateList?.totalDevices : "N/A"}</td>
                                            <td>{privateList?.usedStorage ? privateList?.usedStorage : "N/A"}</td>
                                            <td>{privateList?.remaingStorage ? privateList?.remaingStorage : "N/A"}</td>
                                            <td>{privateList?.totalStorage ? privateList?.totalStorage : "N/A"}</td>
                                            <td>{privateList?.status ? privateList?.status : "N/A"}</td>
                                            <td>{moment(privateList?.created).format('MM-DD-YY')}</td>
                                           
                                        </tr>
                                   
                                </tbody>
                            </table>
                        </div> */}
                                <div className="card" id="account-card">
                                    <div className="card-body">
                                        <h1 id="my-account-title">Client Information</h1>
                                        <form id="account-form">
                                            <div className="row" id="form-row">
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Name</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.name ? privateList?.name : "N/A"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="email-field">
                                                    <div className="form-group" id="email-group">
                                                        <label htmlFor="email-input" id="email-label">Email</label>
                                                        <input
                                                            id="email-input"
                                                            type="email"
                                                            name="personalemail"
                                                            value={privateList?.emailid ? privateList?.emailid : "N/A"}
                                                            disabled
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="phone-field">
                                                    <div className="form-group" id="phone-group">
                                                        <label htmlFor="phone-input" id="phone-label">Phone Number</label>
                                                        <input
                                                            id="phone-input"
                                                            type="text"
                                                            name="personalphone"
                                                            value={privateList?.contactno ? privateList?.contactno : "N/A"}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Status</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.status ? privateList?.status : "N/A"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Created</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.created ? moment(privateList?.created).format('MM-DD-YY') : "N/A"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-body">
                                        <h1 id="my-account-title">Files</h1>
                                        <form id="account-form">
                                            <div className="row" id="form-row">
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Total Albums</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.totalAlbums ? privateList?.totalAlbums : "0"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="email-field">
                                                    <div className="form-group" id="email-group">
                                                        <label htmlFor="email-input" id="email-label">Total Videos</label>
                                                        <input
                                                            id="email-input"
                                                            type="email"
                                                            name="personalemail"
                                                            value={privateList?.totalVideos ? privateList?.totalVideos : "0"}
                                                            disabled
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="phone-field">
                                                    <div className="form-group" id="phone-group">
                                                        <label htmlFor="phone-input" id="phone-label">Total Images</label>
                                                        <input
                                                            id="phone-input"
                                                            type="text"
                                                            name="personalphone"
                                                            value={privateList?.totalImages ? privateList?.totalImages : "0"}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Total Devices</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.totalDevices ? privateList?.totalDevices : "0"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Total Shares</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.totalShares ? privateList?.totalShares : "0"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-body">
                                        <h1 id="my-account-title">Storage</h1>
                                        <form id="account-form">
                                            <div className="row" id="form-row">
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Used Storage</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.usedStorage ? privateList?.usedStorage : "0"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Remaining Storage</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.remaingStorage ? privateList?.remaingStorage : "0"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="name-field">
                                                    <div className="form-group" id="name-group">
                                                        <label htmlFor="name-input" id="name-label">Total Storage</label>
                                                        <input
                                                            id="name-input"
                                                            type="text"
                                                            name="name"
                                                            value={privateList?.totalStorage ? privateList?.totalStorage : "0"}
                                                            // onChange={(e) => setUser({ ...user, name: e.target.value })}
                                                            // onFocus={handleFocus}
                                                            className="form-control"
                                                            readonly
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {transactionList && transactionList.length > 0 &&
                                    <div id="pageTitle" className="page-title">
                                        <h1 id="transactionPageHeading" className="sm-heading lite-text">Transaction History</h1>
                                    </div>}
                                {transactionList && transactionList.length > 0 &&
                                    <div id="dataTableWrapper" className="div-table">
                                        {transactionList && transactionList.length > 0 ?
                                            <DataTable
                                                id="transactionDataTable"
                                                columns={columns}
                                                data={transactionList}
                                                pagination
                                                className="transaction-history-table"
                                                customTheme={myNewTheme}
                                            // selectableRows
                                            // clearSelectedRows={toggledClearRows}
                                            // onSelectedRowsChange={handleChange}
                                            /> : transactionList && transactionList.length === 0 ? noRecordsFound() : <Loader id="dataLoader" />}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* /  <Footer id="footer" /> */}
            </div>
        </div>


    )
}
export default ViewClients;