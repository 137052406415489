import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Footer from './components/Footer';
import Home1 from './components/Home1';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import MainContent from './components/MainContent';
import Wizard from './components/Wizard';
import MyAccount from './components/myaccount2';
import FileUpload from './components/FileUpload';
import PaymentPage from './components/PaymentPage';
import TransactionHistoryTable from './components/TransactionHistoryTable';
import MyDevices from './components/MyDevices'
import Price from './components/Price';
import Settings from './components/Settings';
import About from './components/About';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import RefundPolicy from './components/RefundPolicy';
import Faq from './components/Faq';
import ManageContent from './components/ManageContent';
import MyClips from './components/MyClips';
import Albums from './components/Albums';
import CreateAlbum from './components/CreateAlbum';
import ViewAlbum from './components/ViewAlbum2';
import PublicAlbum from './components/publicAlbum';
import Editor from './components/Aimage';
import './App.css';
import Shared from './components/Shared';
import { CommonProvider } from './components/CommonContext';

import ImageEditorPage from './components/ImageEditor';
import SubscriptionPlan from './components/SubscriptionPlan';
import ReactImageEditor from './components/photoEditor';
import Drag from './components/drag';
import NotAvailable from './components/notAvailable';
import InfiniteScroll from './components/infinitescroll';
import ContactUs from "./components/ContactUs";
import HowitWorks from "./components/HowitWorks";
import Loader from "./components/Loader";
import QRCode from "./components/QRCode";
import Client from "./components/Client";
import ViewClients from "./components/ViewClients";

function App() {
    return (
        <CommonProvider>
            <Router>
                <AppContent />
            </Router>
        </CommonProvider>
    );
}


function AppContent() {
    const location = useLocation();
    const [geolocation, setGeoLocation] = useState('');
    const [countryCode, setCountry] = useState(false);
    const [configLoaded, setconfigLoaded] = useState(false);
    const [load, pageLoad] = useState(false);
    /// Hide footer only for the exact route /album/:clientId/:id and event using regex 
    const showFooter = !/^\/album\/[^/]+\/[^/]+\/[^/]+$/.test(location.pathname) && !/^\/event+$/.test(location.pathname);
    //&& !/^\/all-files+$/.test(location.pathname);
    useEffect(() => {
        getCountry(geolocation)
    }, [configLoaded]);

    useEffect(() => {
        // Simulating async setting of window.site
        const checkSiteObject = () => {
          if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
          
            setGeoLocation(window.site.common.geoLocatoinCloudfrontUrl)
            setconfigLoaded(true)
          } else {
            setTimeout(checkSiteObject, 1000); // Check again after a short delay
          }
        };
    
        checkSiteObject();
      }, []);


    const getCountry = async (location) => {
        try {
            const geoResponse = await axios.get(location);
            //  console.log('geoResponse ', geoResponse)
            let country = geoResponse?.data?.headers?.["cloudfront-viewer-country"][0].value
            setCountry(country)
            pageLoad(true)
            //  console.log(country, "country")
        } catch (error) {
            console.error('Error counryCode', error);
        }
    };


    const HtmlPage = () => {
        window.location.href = "/how-it-works.html"; // Redirect to the static HTML file
        return null;
      };

    //  console.log('countryCode ', countryCode)

    return (
        <>
            {load && countryCode !=undefined && countryCode != false && (countryCode == "IN" || countryCode == "US") ? 
            <Routes>

                <Route path="/" element={<Home1 />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/all-files" element={<MainContent />} />
                <Route path="/albums" element={<Albums />} />
                <Route path="/event" element={<CreateAlbum />} />
                <Route path="/fileupload" element={<FileUpload />} />
                <Route path="/shared" element={<Shared />} />
                <Route path="/editor" element={<Editor />} />
                <Route path="/my-clips" element={<MyClips />} />
                <Route path="/imageeditor" element={<ImageEditorPage />} />
                <Route path="/myaccount" element={<MyAccount />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/viewalbum/:id" element={<ViewAlbum />} />
                <Route path="/transaction-history" element={<TransactionHistoryTable />} />
                <Route path="/mydevices" element={<MyDevices />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/price" element={<Price />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/subscription" element={<SubscriptionPlan />} />
                <Route path="/manage-content" element={<ManageContent />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/album/:clientId/:id/:typeId" element={<PublicAlbum />} />
                <Route path="/edit/:id" element={<ReactImageEditor />} />
                <Route path="/howitworks" element={<HtmlPage />} />
                <Route path="/drag" element={<Drag />} />
                <Route path="/scroll" element={<InfiniteScroll />} />
                <Route path="/qrcode/:code/:name" element={<QRCode />} />
                <Route path="/client" element={<Client />} />
                <Route path="/viewClient" element={<ViewClients />} />
            </Routes> : load && countryCode !=undefined && countryCode != false ?
                <Routes>
                    <Route path="/" element={<NotAvailable />} />
                    <Route path="*" element={<Navigate to="/" replace />} /> 
                </Routes>
                : 
                <Routes>
                    <Route path="/" element={<Loader />} />
                </Routes>
            }

            {/* Conditionally render the Footer */}
            {/* {showFooter && <Footer />} */}
        </>
    );
}

export default App;
