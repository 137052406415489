import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { CommonContext } from './CommonContext';
let { appname, lambda } = window.app;

const Navbar = () => {
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { navOpen, setNav } = useContext(CommonContext);
  const navigate = useNavigate();
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);
      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();

    loadTheme()

  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, []);

  const handleClick = (e) => {
    if (!localStorage.getItem("token")) {
      navigate("/")
    } else {
      navigate("/dashboard")
    }
  }
  const fetchUserData = async () => {

    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);
      if (response.data?.result === "Client not found") {
        localStorage.removeItem("token");
        localStorage.removeItem("currentSessionClientTime");
        localStorage.removeItem("clientid");
        navigate('/signin');
      } else if (response.status === 200 && response.data?.result?.[0]) {
        setUserData(response.data.result[0]);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = () => {
    navigate("/fileupload");
  };

  const handleProfile = () => {
    navigate("/myaccount");
  };

  const handleSignOut = async () => {

    const payload = { emailid: userData?.emailid };
    setIsSigningOut(true);
    try {
      const response = await axios.post(`${lambda}/signout?appname=${appname}`, payload);

      if (response.status === 200 && response.data?.result === 'Success') {
        localStorage.removeItem("token");
        localStorage.removeItem("currentSessionClientTime");
        localStorage.removeItem("clientid");
        localStorage.clear();
        navigate('/signin'); // Redirect to sign-in page after sign-out

      } else {
        throw new Error('Sign-out failed. Please try again later.');
      }
    } catch (error) {
      console.error('Error signing out:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setIsSigningOut(false);
    }
  };

  const loadTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    const rootElem = document.documentElement;
    // If there's a saved theme, apply it
    if (savedTheme) {
      rootElem.setAttribute('data-theme', savedTheme);
      console.log(`Loaded saved theme: ${savedTheme}`);
    } else {
      // If no saved theme, set a default theme
      rootElem.setAttribute('data-theme', 'light');
      console.log('No saved theme found, setting default theme: light');
    }
  }

  const switchTheme = () => {
    const rootElem = document.documentElement;
    let dataTheme = rootElem.getAttribute('data-theme');
    // Determine new theme
    const newTheme = (dataTheme === 'light') ? 'dark' : 'light';
    // Set the new theme
    rootElem.setAttribute('data-theme', newTheme);
    // Save the new theme in localStorage
    localStorage.setItem('theme', newTheme);
    console.log(`Theme switched to: ${newTheme}`);
  }

  const setButton = (e) => {
    setNav(!navOpen);
  };

  // document.querySelector('#theme-switcher').addEventListener('click', switchTheme)

  return (

    <div className="shadow-sm nav-wrapper" id="mainNav">
    <div className="logo-section" id="logoSection">
      <button className="nav_toggle" type="button" onClick={(e) => setButton(e)} id="navToggleBtn">
        <span className="material-symbols-outlined" id="menuIcon">
          menu
        </span>
      </button>
      <a className="navbar-brand fw-bold" onClick={(e) => handleClick(e)} id="navbarBrand">
        <img src={`${image}images/app/images/logo-dark1.png`} className="brand-img lightlogo" alt="Logo" id="lightLogo" />
        <img src={`${image}images/app/images/logo-white1.png`} className="brand-img darklogo" alt="Logo" id="darkLogo" />
      </a>
    </div>
    <div className="menu-section" id="menuSection">
      <div id="theme-switcher" className="theme-switcher" onClick={(e) => switchTheme(e)}>
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" id="sun-icon">
          <path d="M460-804.62V-900h40v95.38h-40ZM460-60v-95.38h40V-60h-40Zm344.62-400v-40H900v40h-95.38ZM60-460v-40h95.38v40H60Zm672.62-244.31-28.31-28.31L762-790.77 790.77-762l-58.15 57.69ZM198-169.23 169.23-198l58.15-57.69 28.31 28.31L198-169.23Zm564 0-57.69-58.15 28.31-28.31L790.77-198 762-169.23ZM227.38-704.31 169.23-762 198-790.77l57.69 58.15-28.31 28.31ZM480.18-280q-83.26 0-141.72-58.28Q280-396.56 280-479.82q0-83.26 58.28-141.72Q396.56-680 479.82-680q83.26 0 141.72 58.28Q680-563.44 680-480.18q0 83.26-58.28 141.72Q563.44-280 480.18-280Zm-.18-40q67 0 113.5-46.5T640-480q0-67-46.5-113.5T480-640q-67 0-113.5 46.5T320-480q0 67 46.5 113.5T480-320Zm0-160Z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" id="moon-icon" height="20px" viewBox="0 -960 960 960" width="20px">
          <path d="M481.15-140q-141.66 0-240.83-99.17-99.16-99.16-99.16-240.83 0-135.77 92.11-232.88 92.11-97.12 225.57-105.2 8.62 0 16.93.62 8.3.62 16.3 1.85-30.61 28.61-48.76 69.15-18.16 40.54-18.16 86.46 0 98.33 68.84 167.17Q562.82-424 661.15-424q46.54 0 86.77-18.15 40.23-18.16 68.46-48.77 1.23 8 1.85 16.31.61 8.3.61 16.92-7.69 133.46-104.8 225.57Q616.92-140 481.15-140Zm0-60q88 0 158-48.5t102-126.5q-20 5-40 8t-40 3q-123 0-209.5-86.5T365.15-660q0-20 3-40t8-40q-78 32-126.5 102t-48.5 158q0 116 82 198t198 82Zm-10-270Z" />
        </svg>
      </div>
  
      <div className="dropdown profile-dd" id="profileDropdown">
        <button className="profile-pic" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="profilePicBtn">
          <img src={`${image}images/app/images/profile-pic.png`} alt="Profile" id="profilePic" />
        </button>
  
        <ul className="dropdown-menu" data-popper-placement="bottom-end" style={{"position": "absolute", "inset": "0px 0px auto auto", "margin": "0px", "transform": "translate3d(0px, 50.4px, 0px)"}} id="profileMenu">
          <li id="profileSection">
            <div className="profile-section">
              <div className="profile-block">
                <img src={`${image}images/app/images/profile-pic.png`} className="profile" alt="Profile" id="profilePicImg" />
              </div>
              <div className="profile-text">
                <h3 className="username" id="username">{userData?.name.toUpperCase()}</h3>
              </div>
            </div>
          </li>
          <li className="text" onClick={handleProfile} id="viewProfile">
            <span className="material-symbols-outlined me-3">person</span>
            <span className="md-txt">Profile</span>
          </li>
          <li className="text" onClick={handleSignOut} id="logOut">
            <span className="material-symbols-outlined me-3">logout</span>
            <span className="md-txt">Log Out</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
    // <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
    //   <div className="container-fluid">
    //     <a className="navbar-brand fw-bold" onClick={handleClick}>

    //       <img src={`${image}images/app/images/logo-dark1.png`} className="brand-img" alt="Logo" />
    //     </a>
    //     <button
    //       className="navbar-toggler"
    //       type="button"
    //       data-bs-toggle="collapse"
    //       data-bs-target="#navbarResponsive"
    //       aria-controls="navbarResponsive"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       Menu
    //       <i className="bi-list"></i>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarResponsive">
    //       <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
    //         <li className="nav-item">
    //           {/* <button className="btn btn-primary rounded-pill px-3 py-2 mb-2 mb-lg-0" onClick={handleUpload}>
    //             <span className="d-flex align-items-center">
    //               <span>Upload</span>
    //             </span>
    //             <span className="material-icons ms-2">upload</span>
    //           </button> */}
    //         </li>
    //       </ul>
    //       <div className="dropdown profile-dd">
    //         <button className="profile-pic" type="button" data-bs-toggle="dropdown" aria-expanded="false">

    //           <img src={`${image}images/app/images/profile-pic.png`} alt="Profile" />
    //         </button>
    //         <ul className="dropdown-menu">
    //           <li><a className="dropdown-item md-txt" onClick={handleProfile}>My profile</a></li>
    //           <li><a className="dropdown-item md-txt" onClick={handleSignOut}>Sign out</a></li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="modal fade large_popup" id="largepopup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    //     <div className="modal-dialog">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <h5 className="modal-title" id="exampleModalLabel">Upload Files</h5>
    //           <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    //         </div>
    //         <div className="modal-body">
    //           <div className="upload-zone">
    //             <div className="upload-icon">
    //               <span className="material-icons">file_upload</span>
    //             </div>
    //             <h6 className="mt-3 mb-3">Drag and Drop files here</h6>

    //             <input
    //               type="file"
    //               id="file-upload"
    //               multiple
    //               className="file-input"
    //               style={{ display: 'none' }}
    //             />
    //             <label
    //               htmlFor="file-upload"
    //               className="btn btn-primary rounded-pill px-3 py-2 mb-2 mb-lg-0 mt-3"
    //             >
    //               Choose files here
    //             </label>
    //             <p className="upload-zone-text mt-5">
    //               By submitting your videos to Fusion Pics, you acknowledge that you agree
    //               to, <br /> Fusion Pics <a href="#">Terms of Service</a> &amp; <a href="#">Privacy Policy</a>
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </nav>
  );
};

export default Navbar;
